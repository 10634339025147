.ways {
    $gap: 0;
    margin-top: $gap;
    position: relative;
    padding-top: ac(57px, 40px);
    padding-bottom: ac(150px, 70px);
    background-color: var(--tuna);

    h2 {
        color: var(--white);
    }

    &__decor {
        position: absolute;
        width: ac(577px, 150px, 768, 1440);
        height: ac(577px, 150px, 768, 1440);
        right: ac(-346px, -100px, 768, 1440);
        bottom: ac(-163px, -50px, 768, 1440);

        img {
            object-fit: contain;
        }
    }

    &__wrapper {
        margin-top: ac(39px, 25px);
        display: flex;
        flex-wrap: wrap;
        gap: ac(30px, 15px) 15px;
        margin-right: -15px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 3 - 15px);
    }

    &__icon {
        width: ac(70px, 60px);
        height: ac(70px, 60px);

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__title {
        margin-top: ac(15px, 10px);
        position: relative;
        font-weight: 600;
        font-size: ac(23px, 18px);
        line-height: ac(28px, 22px);
        color: var(--white);

        &::before {
            content: '';
            position: absolute;
            bottom: ac(-10px, -5px);
            left: 0;
            width: 90%;
            max-width: 191px;
            height: 1px;
            background-color: var(--blue);
        }
    }

    &__descr {
        margin-top: ac(21px, 15px);

        .simplebar-scrollbar::before {
            background: var(--white);
        }

        &[data-simplebar] {
            max-height: 88px;
            padding-right: 15px;
            margin-right: -15px;
            width: 100%;
        }

        &, &>* {
            font-size: 15px;
            line-height: 22px;
            color: var(--white);
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }
    }
    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}