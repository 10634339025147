.travel-section {
    $gap: 0;
    margin-top: $gap;
}

.travel-section--org {
    $gap: ac(150px, 99px);
    margin-top: $gap;
}

.travel-section {
    position: relative;
    padding: ac(150px, 50px) 0;
    background-color: var(--lily);
    border-radius: 0 0 30px 30px;
    z-index: 1;

    &--org {
        padding: 0;
        background-color: transparent;
    }

    &-content {
        max-width: 836px;
    }

    .title {
        margin-bottom: 40px;
    }

    &-sb-title {
        color: var(--blue);
        margin-bottom: 17px;
        font-size: 16px;
        font-weight: 600;

        &.sb-title-2 {
            font-size: ac(20px, 18px);
            margin-bottom: 20px;
        }
    }

    &-text {
        margin-bottom: ac(40px, 25px);
        max-width: 707px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;

        p {
            line-height: inherit;
        }
    }

    &-terms {
        max-width: 603px;

        h4 {
            font-size: 15px;
            margin-bottom: 9px;
        }

        p {
            color: var(--pastel);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
        }
    }

    &-cl-form {
        margin-bottom: ac(66px, 30px);
    }

    &-cl-form-2 {
        margin-bottom: ac(47px, 0px);
    }

    &--org {
        padding-bottom: 0;

        .travel-section-content {
            max-width: 100%;
        }

        .travel-section-cl-form {
            margin-bottom: 0;
        }

        @mixin media 1150 {
            .inputs-row {
                flex-wrap: wrap;

                label {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

.inputs-row {
    margin-bottom: 35px;
    display: flex;
    column-gap: 9px;

    label {
        font-size: ac(24px, 16px);
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
            padding-right: 9px;
            flex-shrink: 0;
        }
    }
    input{
        background: transparent !important;
    }

    .arrow-btn {
        margin-top: -7px;
        margin-bottom: 4px;
        transform: rotate(15deg);

        .icon-arrow {
            transform: rotate(30deg);
        }
    }
}

.input-field {
    @mixin transparent-bg-input inherit;

    border-bottom: 2px solid var(--blue);
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    flex-grow: 1;
    width: auto;
    min-width: 0;
    line-height: normal;
    height: auto;

    &::placeholder {
        opacity: 0.5;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;

    }
}

@media (max-width: 768px) {
    .inputs-row {
        flex-wrap: wrap;
        margin-bottom: 0;

        label {
            margin-bottom: 25px;
            width: 100%;
        }

        .input-field {
            max-width: 100% !important;
        }

        .arrow-btn {
            margin: 0 0 0 auto;
        }
    }
}