.news {
	$gap: ac(203px, 99px);
	margin-top: $gap;
}

.news--article {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}

.news--prof {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}

.news--org {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}
.news--category  {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}

.news {
	&__btn-box {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 15px;
		margin-left: auto;
	}

	&--prof {
		.news {
			&__btn-box {
				display: inline-flex;
				flex-wrap: wrap;
				gap: 15px;
				margin-left: auto;
			}

			&__btn {
				margin-left: 0;
			}

			&__title {
				max-width: calc(100% - 526px);
			}
		}
	}

	&--category {

		.news {
			&__wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;

				&>.btn {
					margin-top: ac(40px, 30px);
					padding: 4px 9px;
				}
			}

			&__card-box {
				display: flex;
				flex-wrap: wrap;
				gap: ac(40px, 15px) 15px;
				margin-right: -15px;
			}

			&__card {
				width: calc(100% / 3 - 15px);
				color: inherit;
				height: auto;

				&:nth-child(4n + 1) {
					.news__head {
						background-color: var(--blue);
	
						.arrow-btn {
							background-color: var(--white);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
						.news__name {
							color: var(--blue);
						}
					}
				}

				&:nth-child(4n + 2) {
					.news__head {
						background-color: var(--tuna);
	
						.arrow-btn {
							background-color: var(--blue);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					.news__name {
						color: var(--blue);
					}
	
					&:hover {
						.news__head {
							background-color: var(--blue);
						}
	
						.news__name {
							color: var(--tuna);
						}
					}
				}

				&:nth-child(4n + 3) {
					.news__head {
						background-color: var(--pastel);
	
						.arrow-btn {
							background-color: var(--tuna);
	
							span {
								color: var(--white);
							}
						}
					}
	
					.news__name {
						color: var(--tuna);
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
	
						.news__name {
							color: var(--pastel);
						}
					}
				}

				&:nth-child(4n + 4) {
					.news__head {
						background-color: var(--alice);
	
						.arrow-btn {
							background-color: var(--white);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					.news__name {
						color: var(--blue);
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
	
						.news__name {
							color: var(--pastel);
						}
					}
				}
			}

			&__head {
				min-height: ac(228px, 180px);
			}
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__title {
		margin-right: 20px;
		width: 100%;
		max-width: calc(100% - 526px);
	}

	&__btn {
		margin-left: auto;
	}

	&__slider-box {
		display: flex;
		margin-top: ac(40px, 30px);
		width: 100%;
	}

	&__slider {
		width: 100% !important;
		overflow: visible !important;
	}

	&__slide {
		&:nth-child(4n + 1) {
			.news__card {
				.news__head {
					background-color: var(--blue);

					.arrow-btn {
						background-color: var(--white);

						span {
							color: var(--tuna);
						}
					}
				}

				&:hover {
					.news__head {
						background-color: var(--tuna);
					}
					.news__name {
						color: var(--blue);
					}
				}
			}
		}
        
		&:nth-child(4n + 2) {
			.news__card {
				.news__head {
					background-color: var(--tuna);

					.arrow-btn {
						background-color: var(--blue);

						span {
							color: var(--tuna);
						}
					}
				}

				.news__name {
					color: var(--blue);
				}

				&:hover {
					.news__head {
						background-color: var(--blue);
					}

					.news__name {
						color: var(--tuna);
					}
				}
			}
		}

		&:nth-child(4n + 3) {
			.news__card {
				.news__head {
					background-color: var(--pastel);

					.arrow-btn {
						background-color: var(--tuna);

						span {
							color: var(--white);
						}
					}
				}

				.news__name {
					color: var(--tuna);
				}

				&:hover {
					.news__head {
						background-color: var(--tuna);
					}

					.news__name {
						color: var(--pastel);
					}
				}
			}
		}
		&:nth-child(4n + 4) {
			.news__card {
				.news__head {
					background-color: var(--alice);

					.arrow-btn {
						background-color: var(--white);

						span {
							color: var(--tuna);
						}
					}
				}

				.news__name {
					color: var(--blue);
				}

				&:hover {
					.news__head {
						background-color: var(--blue);
					}

					.news__name {
						color: var(--alice);
					}
				}
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;

		& > .btn {
			margin-top: ac(14px, 5px);
			min-height: 31px;
			padding: 0 11px;
			border: 0;
			background-color: var(--pastel);

			span {
				line-height: 31px;
				margin-right: 0;
			}
		}
	}

	&__head {
		position: relative;
		padding: ac(30px, 35px) ac(30px, 25px) ac(44px, 25px);
		height: 100%;
		transition: 0.3s ease;

		.arrow-btn {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 29px;
			height: 29px;

			span {
				line-height: 29px;
				font-size: 14px;
			}
		}
	}

	&__name {
		max-width: calc(100% - 25px);
		max-height: ac(168px, 136px);
		@mixin max-line-length 4;
		font-weight: 700;
		font-size: ac(31px, 28px);
		line-height: ac(39px, 34px);
		transition: 0.3s ease;
	}

	&__descr {
		margin-top: 8px;
		flex: 0 0 auto;
		font-weight: 300;
		font-size: 15px;
		line-height: 22px;
		max-height: 44px;
		height: 44px;
		color: var(--tuna);

		@mixin max-line-length 2;
	}

	@mixin tab {
		&__btn-box {
			order: 1;
			margin-left: 0;
			margin-top: ac(40px, 20px);
		}

		&__title {
			max-width: 100%;
		}

		&--category {
			.news__card {
				width: calc(100% / 2 - 15px);
			}
		}

		&--prof {
			.news__btn-box {
				order: 1;
				margin-left: 0;
				margin-top: ac(40px, 20px);
			}

			.news__title {
				max-width: 100%;
			}
		}
    }

	@mixin mob-xl {
		&__title {
			margin-right: 0;
			max-width: 100%;
		}

		&__btn {
			margin: 0px auto 0;
			order: 1;
		}

		&--prof {
			.news__btn {
				margin-top: 0;
			}
		}

		&--category {
			.news__card {
				width: calc(100% - 15px);
			}
		}
	}
}
