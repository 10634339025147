.possibilities {
    $gap: ac(175px, 80px);
    margin-top: $gap;
}

.possibilities--bg {
    $gap: 0;
    margin-top: $gap;
}

.possibilities--alt {
    $gap: ac(125px, 80px);
    margin-top: $gap;
}

.possibilities {
    position: relative;

    &--alt {
        .possibilities {
            &__col {
                max-width: ac(500px, 400px, 768, 1440);
            }

            &__img {
                height: ac(276px, 190px, 768, 1440);
            }
        }
    }

    &--bg {
        padding: ac(110px, 60px) 0;
        background-color: var(--pastel);
    }

    &__decor {
        position: absolute;
        width: ac(577px, 150px, 768, 1440);
        height: ac(577px, 150px, 768, 1440);
        right: ac(-346px, -100px, 768, 1440);
        top: ac(-163px, -50px, 768, 1440);

        img {
            object-fit: contain;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
    }

    &__img {
        flex: 0 0 auto;
        width: 100%;
        max-width: ac(398px, 300px, 768, 1440);
        height: ac(319px, 190px, 768, 1440);

        img {
            border-radius: 30px;
            object-position: center top;
        }
    }

    &__col {
        margin-top: ac(24px, 0px, 1023, 1024);
        width: 100%;
        max-width: ac(603px, 400px, 768, 1440);
        margin-left: ac(118px, 25px, 768, 1440);
    }

    &__title {
        margin-bottom: ac(39px, 25px);
    }

    &__subtitle {
        font-weight: 600;
        font-size: ac(23px, 18px);
        line-height: ac(28px, 22px);
    }

    p:not(.possibilities__subtitle) {
        margin-top: ac(22px, 15px);
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column;
        }

        &__col {
            max-width: 100%;
            margin-left: 0;
        }

        &__img {
            margin-bottom: ac(50px, 25px);
            width: ac(420px, 290px, 320, 768);
            height: ac(260px, 250px, 320, 768);
        }

        &--alt {
            .possibilities__col {
                max-width: 100%;
            }
        }
    }

    @mixin mob-lg {
        &--alt {
            .possibilities__img {
                max-width: 100%;
                width: 100%;

                @mixin aspect-ratio 397, 276;
            }
        }
    }
}