.awards {
	position: relative;
	$gap: ac(150px, 101px);
	margin-top: $gap;
	z-index: 2;

	&__title {
		text-align: center;
		margin-bottom: 42px;
	}

	.slider-nav {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		width: 100%;
		user-select: none;
		
		.arrow-btn {
			margin: 0 5px;
			transform: rotate(15deg);
			width: ac(48px, 37px);
			height: ac(48px, 37px);

			span {
				line-height: ac(48px, 37px);
				font-size: ac(28px, 22px);
				transform: rotate(-15deg);
			}
		}

	}

	&__wrapper {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__slider {
		width: 100% !important;
		overflow: visible !important;
	}

	&__logo {
		flex: 0 0 auto;
		position: relative;
		width: 100%;

		img {
			object-fit: contain;
			height: ac(112px, 103px);
		}

		&-desc {
			position: absolute;
			width: ac(294px, 260px);
			background: var(--white);
			padding: ac(20px, 12px);
			border: 1px solid var(--tuna);
			border-radius: 7px;
			z-index: 10;
			transition: 0.3s ease;
			opacity: 0;
			visibility: hidden;

			pointer-events: none;

			p {
				font-size: 16px;
				line-height: 130%;
			}
		}

		&-title {
			font-weight: 600;
			font-size: 16px;
			margin-top: 15px;
			line-height: 120%;
		}

		&:hover {
			.awards__logo-desc {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__descr {
		font-size: 15px;
		font-weight: 600;
		margin-top: 14px;
		line-height: 19px;
	}

	@mixin mob-lg {
		.awards__logo {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.awards__logo-title {
			text-align: center;
		}
	}
}
