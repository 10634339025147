.partnership {
    $gap: ac(150px, 99px);
    margin-top: $gap;

    padding-top: 40px;

    &__heading {
        max-width: 603px;
    }

    &__desc {

        margin-top: 40px;

        &, &>* {
            font-weight: 600;
            font-size: ac(23px, 20px);
            line-height: 120%;
        }
    }

    &__wrap {
        gap: 20px;
    }

    &__link {
        margin-top: ac(6px, 0px, 1024, 1440);
        display: flex;
        align-items: center;
        padding: ac(30px, 15px) ac(30px, 15px) ac(30px, 15px) ac(103px, 15px);
        position: relative;
        gap: 20px;
        flex-shrink: 0;
        cursor: pointer;
        width: 100%;
        max-width: 500px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            background: var(--tuna);
            width: 100%;
            border-radius: 30px;
            z-index: -1;
            transition: 0.3s ease;
        }

        i {
            transform: rotate(-45deg);
            font-size: 24px;
        }

        .arrow-btn {
            background: var(--blue);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h3 {
            font-size: ac(23px, 20px);
            color: var(--white);
            transition: color 0.3s ease;
        }

        &:hover {
            h3 {
                color: var(--tuna);
            }

            .arrow-btn {
                background: var(--tuna);
            }

            &::before {
                background: var(--pastel);
            }
        }
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column-reverse;
            gap: 40px;
        }

        &__link {
            justify-content: center;
            padding: * 20px;

            &:before {
                left: auto;
                right: 0;
            }
        }
    }
}