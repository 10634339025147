@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap';

:root {
	--font-main: "Montserrat", sans-serif;
	--font-sec: "Figtree", sans-serif;
	--tuna: rgb(8, 2, 35);
	--white: #ffffff;
	--blue: #53BEDB;
	--pastel: #6CA4FF;
	--alice: #2D756E;
	--lily: #EAF7FB;
	--red: #D40059;

	--bg-color: 8 2 35;
	--bg-color-opacity: 8 2 35 / 0;

	--none: 0;
	--main: ac(150px, 100px);
	--sm: ac(45px, 25px);
}
