.gallery {
    $gap: 0;
    margin-top: $gap;
    padding-top: 50px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: var(--pastel);
    }

    &__slider {
        width: 100%  !important;
        overflow: visible !important;
    }

    .swiper-slide {
        display: flex;
    }

    &__img {
        width: 100%;
        height: ac(282px, 220px, 1024, 1440);
        clip-path: url(#gallery-mask);
		overflow: hidden;
    }

    @mixin tab {
        &__img {
            height: ac(282px, 180px, 650, 1024);
        }
    } 

    @mixin mob-xl {
        &__img {
            height: ac(260px, 180px, 431, 650);
        }
    }

    @mixin mob {
        &__img {
            height: ac(350px, 260px, 320, 450);
        }
    }
}