.team-video {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.team-video--prof {
    $gap: 0;
    margin-top: $gap;
}

.team-video--org {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.team-video {
    padding: 80px 0 ac(115px, 80px);
    color: var(--white);
    background: var(--tuna);
    border-radius: 30px;

    &--prof {
        padding: ac(150px, 80px) 0 ac(150px, 80px);
        border-radius: 0 0 30px 30px;

        .contacts__link {
            font-size: ac(18px, 16px);
            font-weight: 600;
        }

        .btn {
            margin-top: ac(40px, 20px);
        }

        .team-video {
            &__decor {
                width: ac(333px, 180px, 320, 1440);
                height: ac(333px, 180px, 320, 1440);
                z-index: -1;

                &:nth-child(1) {
                    left: ac(-146px, -20px, 320, 1440);
                    top: ac(100px, -25px, 500, 1440);
                }
        
                &:nth-child(2) {
                    right: ac(-116px, -30px, 320, 1440);
                    top: ac(-130px, -26px, 320, 1440);
                }
            }

            &__wrapper {
                display: flex;
                align-items: flex-start;
                max-width: 1015px;
                margin: 0 auto;
                position: relative;
                gap: 15px;
                justify-content: space-between;
            }

            &__name, &__subtitle {
                line-height: 130%;
                color: var(--tuna);
            }

            &__name {
                font-weight: 700;
                font-size: ac(31px, 28px);
                margin-bottom: 18px;
            }

            &__subtitle {
                font-weight: 600;
                font-size: ac(23px, 18px);
                margin-bottom: 17px;
            }

            &__box {
                max-width: 603px;
                position: relative;
                z-index: 1;
            }

            &__card {
                background: var(--white);
                border-radius: 30px;
                padding: ac(40px, 25px) ac(30px, 15px) ac(36px, 25px);
                position: relative;
                z-index: 2;
                max-width: 397px;
                width: 100%;
            }
        }
    }

    &--org {
        position: relative;
        padding: ac(150px, 80px) 0 0;
        background-color: transparent;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 84%;
            background-color: var(--tuna);
            border-radius: 30px;
        }

        .team-video {
            &__wrapper {
                position: relative;
                display: flex;
                gap: 35px 15px;
                max-width: 1015px;
                margin: 0 auto;
            }

            &__video {
                position: relative;
                z-index: 1;

                h3 {
                    margin-bottom: ac(30px, 15px);
                }
            }

            &__decor {
                position: absolute;
                width: ac(350px, 180px, 320, 1440);
                height: ac(350px, 180px, 320, 1440);
        
                &:nth-child(1) {
                    left: ac(-76px, -20px, 320, 1440);
                    top: ac(78px, 120px);
                }
        
                &:nth-child(2) {
                    right: ac(-130px, -30px, 320, 1440);
                    top: ac(0px, 56px, 320, 1440);
                }
            }
        }
    }

    &__content {
        max-width: 810px;
        margin: 0 auto;
    }

    &__title {
        margin-bottom: 48px;
    }

    &__decor {
        position: absolute;
        width: ac(481px, 180px, 320, 1440);
        height: ac(481px, 180px, 320, 1440);

        img {
            object-fit: contain;
        }

        &:nth-child(1) {
            left: ac(-124px, -20px, 320, 1440);
            top: 10px;
        }

        &:nth-child(2) {
            right: ac(-173px, -30px, 320, 1440);
            top: ac(-64px, -26px, 320, 1440);
        }
    }

    @mixin tab {
        &--org {
            .team-video__wrapper {
                flex-direction: column;
                max-width: 700px;
            }
        }

        &--prof {
            .team-video__wrapper {
                flex-direction: column-reverse;
            }

            .team-video__box {
                max-width: unset;
            }
        }
    }
}