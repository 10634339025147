.mission {
	$gap: ac(223px, 80px);
	margin-top: $gap;
}

.mission--careers  {
	$gap: ac(223px, 80px);
	margin-top: $gap;
}

.mission--org {
	$gap: ac(223px, 80px);
	margin-top: $gap;
}

.mission {
	position: relative;
	z-index: 2;

	&--home {
		.mission__card {
			flex: 1.3;
			&.active {
				flex: 10;
			}
		}
	}

	&--org {
		.mission {
			&__title {
				font-family: var(--font-sec);
			}

			&__hidden {
				top: 75px;
			}

			&__card {
				flex: 1.8;

				&.active {
					flex: 10;
					padding-top: 20px !important;

					& .mission__text {
						max-height: 230px !important;
					}
				}
			}
		}
	}

	&--careers {
		position: relative;
		padding-bottom: ac(150px, 70px);

		.container {
			max-width: 1178px;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: ac(378px, 300px);
			background-color: var(--tuna);
			z-index: -1;
			border-radius: 30px;
		}

		.mission {
			&__wrapper {
				flex-direction: column;
			}

			&__title {
				max-width: 603px;
				margin-left: ac(42px, 0px, 1024, 1440);
				margin-right: 0;
			}

			&__card-box {
				width: 100%;
				margin-top: 40px;
				margin-left: auto;
				margin-right: auto;
			}

			&__name {
				letter-spacing: 0;
			}
		}

		.mission__card {
			flex: 1.3;
			&.active {
				flex: 10;
			}

			&>.mission__name {
				line-height: 120%;
			}

			@mixin desk-sm {
				flex: 1.6;

				&.active {
					flex: 10;
				}
			}
		}
	}

	&__title {
		max-width: ac(273px, 215px, 1024, 1440);
		margin-right: 36px;

		span {
			color: var(--blue);
		}
	}

	&__wrapper, &__card-box {
		display: flex;
	}

	&__card-box {
		width: ac(913px, 696px, 1024, 1440);

		@mixin tab {
			width: 100%;
		}
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 26px 15px ac(32px, 27px);
		overflow: hidden;
		
		flex: 1.6;
        min-height: 335px;
		background-color: var(--white);
		border: 3px solid var(--blue);
		border-radius: 30px;
		transition: 0.3s ease;
		cursor: pointer;

		&:hover {
			background-color: #EAF7FB;
		}

		&:last-child {
			.mission__hidden {
				width: calc(100% - ac(206px, 100px, 1024, 1440));
				
			}
		}

		&:not(:last-child) {
			margin-right: -62px;
			padding-right: 75px;
		}

		&>.mission__name {
			max-height: 204px;
			margin-left: auto;
			writing-mode: vertical-rl;
			transform: rotate(-180deg);
			transition: 0.3s 0.3s ease;
			margin: auto 0 0 0;
			display: flex;
			align-items: center;
			font-size: ac(23px, 18px);
			line-height: 130%;
			letter-spacing: 0;
		}

		&.active {
			flex: 10;
			padding: ac(42px, 15px) ac(100px, 24px) 15px;
			background-color: #EAF7FB;

			.mission__hidden {
                transform: translateY(0%);
                opacity: 1;
                visibility: visible;
                transition: 0.3s 0.3s ease;
            }

			&>.mission__name {
                transform: translate(100%) rotate(-180deg);
                opacity: 0;
                transition: 0.3s ease;
            }

			.mission__icon {
				width: 33px;
				height: 41px;
				margin-left: 0;
			}

			.mission__text {
				max-height: 214px;
			}
		}
	}

	&__icon {
		width: 35px;
		height: 35px;
		margin-left: 14px;
		margin-bottom: 20px;
		transition: 0.3s ease;
		z-index: 1;

		img {
			object-position: center;
			object-fit: contain;
		}
	}

	&__name {
		margin-bottom: ac(18px, 10px, 1024, 1440);
		font-weight: 700;
		font-size: ac(31px, 20px);
		line-height: ac(40px, 24px);
		transition: 0.3s ease;
		letter-spacing: 0.3px;
	}

	&__hidden {
		top: 100px;
        width: calc(100% - ac(206px, 160px, 1024, 1440));
        max-width: 410px;
        position: absolute;
        transform: translateY(100%);
        opacity: 0;
        transition: 0.3s ease;
        visibility: hidden;
    }

	&__text {
		max-height: 0;
		overflow: hidden;
		transition: 0.3s ease;
	}

	&__descr {
		font-weight: 500;
		font-size: ac(15px, 14px);
		line-height: ac(22px, 20px);
		transition: 0.3s ease;

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: ac(132px, 126px, 1024, 1440);
		}

		.simplebar-content {
			&>div {
				padding-right: 10px;

				& > * {
					&:not(:last-child) {
						margin-bottom: ac(30px, 21px);
					}
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--pastel);
			opacity: 1;
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	@mixin tab {
		&__wrapper {
			flex-direction: column;
		}

		&__title {
			margin-right: 0;
			max-width: unset;
			margin-bottom: ac(50px, 20px);
		}
		
	}

	@mixin mob-xl {
		&__card-box {
			flex-direction: column;
		}

		&__card {
			width: 100%;
			padding: 20px;
			min-height: 100px;

			&:not(:last-child) {
				margin-bottom: -60px;
				padding-bottom: 75px;
				margin-right: 0;
				padding-right: 20px;
			}

			&>.mission__name {
				transform: unset;
				writing-mode: unset;
				margin: 15px 0 0 0;
			}

			&>.mission__icon {
				margin: 0;
			}

			&:last-child {
				.mission__hidden {
					width: calc(100% - 40px);
				}
			}

			&.active {
				min-height: ac(240px, 310px);

				&:not(:last-child) {
					min-height: ac(300px, 370px);
				}
				padding: 20px;

				&>.mission__name {
					transform: translateY(-100%);
				}
			}
		}

		&__hidden {
			width: calc(100% - 40px);
			top: 80px;
		}


	}
}
