.counter {
	$gap: 0;
	margin-top: $gap;
}

.counter--org {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}

.counter {
	padding-top: ac(85px, 80px);
	padding-bottom: ac(150px, 80px);
	background-color: var(--tuna);

	&--org {
		padding: 0;
		background-color: transparent;
	}

	.slider-nav {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		width: 100%;
		user-select: none;
		
		.arrow-btn {
			margin: 0 5px;
			transform: rotate(15deg);
			width: ac(48px, 37px);
			height: ac(48px, 37px);
			background-color: var(--white);

			span {
				color: var(--tuna);
				line-height: ac(48px, 37px);
				font-size: ac(28px, 22px);
				transform: rotate(-15deg);
			}

			&:hover {
				background-color: var(--blue);
			}
		}

	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 1015px;
		width: 100%;
		margin: 0 auto;
	}

	&__slider {
		overflow: hidden !important;
	}

	&__item {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 43px 16px;
		background-color: var(--pastel);
		border-radius: 19px;
	}

	&__val {
		display: flex;
		font-weight: 700;
		font-size: ac(43px, 38px);
		line-height: ac(50px, 46px);
		color: var(--white);
		text-align: center;
	}


	&__title {
		margin-top: 9px;
		font-weight: 600;
		font-size: ac(18px, 16px);
		line-height: ac(22px, 19px);
		text-align: center;
		word-break: break-word;
	}

	@mixin mob {
		&__wrapper {
			margin-right: 0;
		}

		&__item {
			max-width: 100%;
			margin-right: 0;
		}
	}
}
