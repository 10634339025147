.footer {
	$gap: ac(150px, 99px);
	margin-top: $gap;
	padding: ac(81px, 100px) 0 50px;
	background-color: var(--tuna);

	&__head {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	&__item {
		display: flex;
		flex-direction: column;
		width: 48%;
		max-width: 500px;

		.btn {
			padding: ac(6px, 5px) ac(6px, 5px) ac(6px, 5px) ac(31px, 19px);
			margin-top: ac(30px, 13px);
			background-color: var(--pastel);

			& > span {
				&:first-child {
					font-size: ac(18px, 16px);
					line-height: ac(48px, 35px);
					font-weight: 600;
				}
			}

			.arrow-btn {
				margin-left: auto;
				width: ac(48px, 37px);
				height: ac(48px, 37px);
				background-color: var(--tuna);

				span {
					color: var(--blue);
					line-height: ac(48px, 37px);
				}
			}

			&:hover {
				background-color: var(--white);

				.arrow-btn {
					span {
						color: var(--white);
					}
				}
			}
		}
	}

	&__title {
		color: var(--white);
		font-weight: 700;
		font-size: ac(31px, 38px);
		line-height: ac(39px, 46px);
	}

	&__btn-box {
		display: flex;
		flex-wrap: wrap;
		margin-top: 29px;
		padding-top: 30px;
		border-top: 1px solid var(--white);
		margin-bottom: -10px;

		.btn {
			margin-right: ac(15px, 10px);
			margin-bottom: 10px;
		}
	}

	&__contact-box {
		margin-top: 29px;
		padding-top: 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-top: 1px solid var(--white);
		margin-bottom: ac(-27px, -18px);

		& > * {
			margin-bottom: ac(27px, 18px);
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: calc(100% / 6 - 15px);
	}

	&__address,
	&__mail,
	&__tel {
		color: var(--white);
	}

	&__location {
		position: relative;
		font-weight: 700;
		font-size: ac(23px, 20px);
		line-height: ac(28px, 24px);
		color: var(--white);

		&::before {
			content: "";
			position: absolute;
			bottom: ac(-7px, -3px);
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--blue);
		}
	}

	&__address,
	&__tel {
		font-size: 12px;
		line-height: 20px;
	}

	&__address {
		margin-top: ac(17px, 10px);
	}

	&__tel {
		margin-top: ac(13px, 8px);
	}

	&__mail {
		font-weight: 700;
		font-size: ac(23px, 18px);
		line-height: ac(28px, 24px);
	}

	&__logo-box {
		margin-top: 29px;
		padding-top: ac(35px, 31px);
		display: flex;
		justify-content: space-between;
		border-top: 1px solid var(--white);
	}

	&__logo {
		flex: 0 0 auto;
		position: relative;
		width: ac(191px, 140px);
		height: ac(68px, 50px);
		z-index: 1;

		img {
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
				top: 0;
				position: absolute;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__soc-box {
		display: flex;
		flex-direction: column;

		& > span {
			width: 100%;
			font-weight: 600;
			font-size: ac(18px, 16px);
			line-height: ac(22px, 19px);
			color: var(--white);
		}
	}

	&__soc-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-top: 15px;
		margin-right: ac(-20px, -18px);
		margin-bottom: -10px;
	}

	&__soc {
		display: flex;
		justify-content: center;
		width: ac(40px, 35px);
		height: ac(40px, 35px);
		background-color: var(--blue);
		border-radius: 7px;
		margin-right: ac(20px, 18px);
		margin-bottom: 10px;

		span {
			line-height: ac(40px, 35px);
			transition: .3s ease;
		}

		&:hover {
			background-color: var(--pastel);

			span {
				color: var(--tuna);
			}
		}
	}

	&__bottom {
		padding-top: ac(53px, 30px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: -25px;
	}

	&__copy,
	&__link-box {
		margin-top: 25px;
	}

	&__copy,
	&__link {
		color: var(--white);
		font-size: 12px;
		line-height: 16px;
	}

	&__copy {
		margin-right: auto;
		padding-right: 20px;
	}

	&__link-box {
		display: flex;
		flex-wrap: wrap;
		margin-right: -21px;
		margin-bottom: -10px;
	}
	
	&__link {
		margin-right: 18px;
		margin-bottom: 10px;
	}

	@mixin tab {
		&__col {
			width: calc(100% / 3 - 10px);
		}
	}

	@mixin mob-xl {
		&__col {
			width: calc(100% / 2 - 10px);
		}

		&__soc-box {
			& > span {
				text-align: right;
			}
		}
	}

	@mixin mob-lg {
		&__head {
			flex-direction: column;
		}

		&__link-box {
			flex-wrap: nowrap;
			flex-direction: column;
		}

		&__item {
			width: 100%;

			&:not(:first-child) {
				margin-top: 29px;
			}
		}
	}
}
