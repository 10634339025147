.actions {
    $gap: ac(150px, 99px);
    margin-top: $gap;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    &__card {
        padding: ac(40px, 20px) ac(30px, 15px);
        background: var(--pastel);
        color: var(--tuna);
        max-width: 294px;
        border-radius: 30px;

        display: flex;
        flex-direction: column;

        &-title {
            margin-bottom: ac(20px, 15px);
        }

        &-desc {
            margin-bottom: ac(39px, 15px);
            font-weight: 600;
            font-size: ac(23px, 20px);
            line-height: ac(28px, 26px);
        }

        .btn {
            margin-top: auto;
            margin-left: auto;
            width: fit-content;
            border: 1px solid transparent;

            &:hover {
                border-color: var(--white);

                span {
                    color: var(--white);
                }
            }
        }
    }

    @mixin tab {
        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &__card {
            max-width: 100%;
        }
    }

    @mixin mob-xl {
        &__wrap {
            grid-template-columns: 1fr;
        }
    }
}