.topics {
	$gap: ac(117px, 70px);
	margin-top: $gap;

	&__title-box {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: ac(75px, 30px);

		&>* {
			margin-top: 15px;
		}
	}

	&__text-col {
		margin-top: ac(40px, 20px);
	}

	&__title {
		margin-right: ac(145px, 20px, 1024, 1440);
		margin-bottom: 10px;
	}

	&__search {
		position: relative;
		max-width: 397px;
		width: 100%;
	}

	&__input {
		width: 100%;
		padding: 7px 60px 7px ac(30px, 15px);
		background: #FFFFFF;
		border: 1px solid var(--blue);
		border-radius: 7px;
		line-height: ac(46px, 35px);
	}

	&__btn {
		position: absolute;
		right: 7px;
		top: 7px;
		width: ac(48px, 37px);
		height: ac(48px, 37px);

		.icon-arrow {
			line-height: ac(48px, 37px);
			font-size: 21px;
		}
	}

	&__grid {
		&:first-of-type {
			margin-top: 66px;
		}
	}

	&__head {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: ac(67px, 20px);
		padding: ac(39px, 20px) ac(30px, 15px);
		min-height: ac(255px, 220px, 770, 1440);
		cursor: pointer;
		background-color: var(--pastel);
		transition: .3s ease;
		border-radius: 30px 0 30px 30px;

		&::before {
			content: '';
			position: absolute;
			right: 1px;
			top: 0;
			transform: translateX(100%);
			width: ac(67px, 20px);
			height: ac(142px, 50px);
			background-color: var(--pastel);
			border-radius: 0 30px 30px 0;
			transition: .3s ease;
		}

		p {
			font-weight: 700;
			font-size: ac(31px, 20px, 1024, 1440);
			line-height: ac(38px, 32px, 1024, 1440);
			margin-bottom: auto;
		}

		.btn {
			&>span {
				transition: .3s ease;

				&:nth-child(2) {
					opacity: 0;
				}
			}
		}

		&:hover, &.open {
			background-color: var(--tuna);
			color: var(--white);

			&::before {
				background-color: var(--tuna);
			}

			.btn {
				background-color: var(--pastel);
				color: var(--tuna);

				.arrow-btn {
					background-color: var(--white);

					span {
						color: var(--tuna);
					}
				}
			}
		}

		&.open {
			.btn {
				&>span {
					&:nth-child(1) {
						opacity: 0;
						margin-right: 5px;
					}

					&:nth-child(2) {
						opacity: 1;
					}
				}
			}
		}
	}

	&__content {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height .5s ease;

		&.open {
            max-height: 7000px;
			opacity: 1;
			transition: .5s ease;
		}

		&>div {
			padding-top: ac(37px, 30px);

			&:last-child {
				padding-bottom: ac(150px, 30px);
			}

			h4, h5, h6 {
				font-weight: 600;
				max-width: 809px;
				
				&:not(:first-child) {
					margin-top: .6em;
				}
				
				&:not(:last-child) {
					margin-bottom: 0.67em;
				}
			}
	
			h4 {
				&:not(:first-child) {
					margin-top: 2em !important;
					margin-bottom: 1.6em !important;
				}
				font-size: ac(26px, 22px);
				line-height: ac(32px, 28px);

				&+h5 {
					margin-top: 0 !important;
				}
			}
	
			h5 {
				font-size: ac(23px, 20px);
				line-height: ac(28px, 26px);

				&:not(:first-child) {
					margin-top: .7em !important;
				}

			}
	
			h6 {
				&:not(:first-child) {
					margin-top: ac(29px, 20px);
				}

				&:not(:last-child) {
					margin-bottom: ac(19px, 15px);
				}
				font-size: ac(20px, 18px);
				line-height: ac(26px, 24px);
			}
	
			p:not(.topics__download-title) {
				font-size: 15px;
				line-height: 22px;
				font-weight: 500;
				max-width: 1025px;

				img {
					height: auto !important;
					object-fit: contain;
				}
			}
	
			ul {
				margin-top: .5em;
				list-style: none;
				padding-left: 22px;

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				li {
					font-size: 15px;
					line-height: 21px;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						left: -14px;
						top: 9px;
						border-radius: 100%;
						width: 4px;
						height: 4px;
						background-color: var(--tuna);
					}

					&:not(:last-child) {
						margin-bottom: 4px;
					}
				}
			}
	
			&>p {
				margin-bottom: 10px;
				max-width: 912px;
			}

			&>ul {
				margin-bottom: 10px;
				max-width: 706px;
			}
	
			.btn {
				margin-top: 17px;
			}
	
			a:not(.btn, .topics__download) {
				position: relative;
				color: #6284E0;
				font-size: inherit;
	
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					left: 0;
					bottom: 4px;
					background-color: #6284E0;
				}
	
				&:hover {
					color: var(--pastel);
	
					&::before {
						background-color: var(--pastel);
					}
				}
			}
		}
	}

	&__tabs {
		display: flex;
		flex-wrap: wrap;
		padding-top: ac(55px, 20px);
		margin-top: -15px;
		margin-right: ac(-91px, -15px);
		margin-bottom: ac(54px, 45px);
	}

	&__tab {
		position: relative;
		font-weight: 600;
		font-size: ac(23px, 18px);
		line-height: ac(28px, 24px);
		margin-right: ac(91px, 15px);
		margin-top: 15px;
		transition: .3s ease;
		user-select: none;
		cursor: pointer;
		
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -6px;
			width: 0%;
			height: 2px;
			background-color: var(--pastel);
			transition: .3s ease;
		}

		&:hover, &.active {
			color: var(--pastel);
		}

		&.active {
			&::before {
				width: 100%;
			}
		}
	}

    &__tab-content {
        display: none;

        &.active {
            display: block;
        }
    }

    &__accardion {
		border: 1px solid var(--lily);
		border-radius: 7px;
		transition: .3s ease !important;
		background: var(--lily);

		&:not(:last-child) {
			margin-bottom: 15px;
		}
    }

    &__accardion-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		padding: ac(29px, 15px) ac(30px, 15px);
		transition: .3s ease;

		&:hover {
			&>span {
				color: var(--blue);
			}

			.arrow-btn {
				background-color: var(--pastel);
			}
		}

		&>span {
			font-size: ac(23px, 18px);
			line-height: ac(28px, 20px);
			font-weight: 600;
			transition: .3s ease;

			&:first-child {
				margin-right: 20px;
			}
		}

		.arrow-btn {
			background-color: var(--blue);
			height: 29px;
			width: 29px;
			transform: rotate(90deg);
			margin-bottom: auto;

			span {
				color: var(--tuna);
				font-size: 15px;
				line-height: 29px;
			}
		}

		&.open {
			padding-bottom: 20px;

			.arrow-btn {
				background-color: var(--pastel);
				transform: rotate(0deg);
			}
		}
    }

    &__accardion-content {
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: max-height 0.5s ease;
	
		&>div {
			padding: 0px ac(30px, 15px) ac(30px, 15px);
	
			&>p {
				margin-bottom: 10px;
			}
		}

		&.open {
			max-height: 1000px;
		}
    }

	&__download-box {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		row-gap: 15px;
	}

	&__download {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: calc(100% / 3 - 15px);
		margin-right: 15px;
		background-color: var(--blue);
		border-radius: 30px;
		padding: ac(40px, 20px) ac(30px, 15px);
		min-height: ac(209px, 170px);
		transition: .3s ease;

		&:hover {
			background-color: var(--tuna);

			.topics__download-title {
				color: var(--white)
			}

			.btn {
				background-color: var(--pastel);
	
				.arrow-btn {
					background-color: var(--white);

					span {
						color: var(--tuna);
					}
				}
			}
		}

		.btn {
			margin-top: auto !important;
			border: 0;

			.arrow-btn {
				background-color: var(--blue);
			}
		}
	}

	&__download-title {
		font-size: ac(23px, 20px);
		line-height: ac(28px, 24px);
		font-weight: 600;
		margin-bottom: 10px;
		transition: .3s ease;
	}

	@media (min-width: 1024px) {
		&__grid {
			display: grid;
			grid-template-areas:
				"item item item"
				"desc desc desc";
			grid-template-columns: 1fr 1fr 1fr;
			gap: 15px;

			&:last-child {
				.topics__content.open  {
					margin-bottom: 15px;
				}

				.topics__content>div {
					padding-bottom: 0;
				}
			}
		}

		&__content {
			grid-area: desc;
		}
	}

	@mixin tab {
		&__download {
			width: calc(100% / 2 - 15px);
		}
		&__grid {
			&:not(:first-child) {
				margin-top: 15px;
			}
		}

		&__head {
			min-height: 180px;

			&:not(:first-child) {
				margin-top: 15px;
			}
		}
	}

	@mixin tab-sm {

	}

	@mixin mob-lg {
		&__download {
			width: calc(100% - 15px);
		}
	}
}
