.care {
	$gap: 0;
	margin-top: $gap;
	position: relative;
	padding-top: ac(297px, 200px);

	&__decor {
		position: absolute;
		right: ac(-310px, -120px);
		top: ac(-260px, -150px);
		width: ac(542px, 200px);
		height: ac(780px, 400px);

		img {
			object-fit: contain;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: 0.3s ease;
		opacity: 0;

		&.active {
			opacity: 1;
		}

		&:not(.not-img-position) {
			@mixin min-media 1441 {
				img {
					object-position: 50% 20%;
				}
			}

			@mixin mob-lg {
				img {
					object-position: 70% 50%;
				}
			}

			@mixin mob {
				img {
					object-position: 60% 50%;
				}
			}
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
	}

	&__col {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		width: 100%;
		max-width: ac(913px, 700px, 1024, 1440);
	}

	&__content {
		display: flex;
		position: relative;
		padding: 30px 30px 0 0;
		width: 100%;
		transform: translateX(-50%);
		opacity: 0;
		visibility: hidden;
		transition: .3s ease;

		&.active {
			transform: translateX(0%);
			opacity: 1;
			visibility: visible;
			transition: .5s ease;
		}

		&--org {
			position: absolute;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: calc(100% + 50vw);
			height: 100%;
			background-color: var(--tuna);
			z-index: -1;
			transition: 0.3s ease;
			border-radius: 0 30px 0 0;
		}
	}

	&__btn-box {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto 0 110px ac(109px, 60px, 1024, 1440);
	}

	&__btn {
		position: relative;
		max-width: ac(150px, 140px);
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translateX(50%);
		opacity: 0;
		visibility: hidden;
		user-select: none;
		cursor: pointer;

		&.active {
			transform: translateX(0%);
			opacity: 1;
			visibility: visible;
			transition: .5s ease;
		}

		&:hover {
			&::before {
				background-color: var(--blue);
			}

			&>span {
				color: var(--white);
			}
		}

		&--org {
			position: absolute;
		}

		&>span {
			font-weight: 600;
			font-size: ac(23px, 16px);
			line-height: ac(28px, 20px);
			margin-bottom: ac(25px, 10px);
			text-align: center;
			transition: .3s ease;
		}
	
		.arrow-btn {
			transform: rotate(29deg);
			width: ac(40px, 37px);
			height: ac(40px, 37px);
			border-radius: 3px;
			pointer-events: none;

			span {
				line-height: ac(40px, 37px);
				font-size: ac(28px, 22px);
				transform: rotate(-30deg) translate(1px, 1px);
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: ac(0px, 8px);
			left: ac(0px, 30px);
			width: ac(220px, 120px);
			height: ac(220px, 120px);
			background-color: var(--white);
			border-radius: 30px;
			transform: rotate(-15deg) translate(-10%,-24%);
			z-index: -1;
			transition: .3s ease;
		}
	}

	&__title-box {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: ac(300px, 280px, 1024, 1440);
		width: 100%;
		flex: 0 0 auto;
		margin-right: ac(113px, 40px, 1024, 1440);

		&::before {
			content: '';
			position: absolute;
			top: ac(0px, 20px, 1024, 1440);
			right: ac(0px, 50px, 1024, 1440);
			width: ac(490px, 400px, 1024, 1440);
			height: ac(490px, 400px, 1024, 1440);
			background-color: var(--blue);
			transform: rotate(-150deg) translate(1%, 12%);
			border-radius: 60px;
			z-index: -1;
		}

		.btn {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	&__icon {
		width: ac(66px, 51px);
		height: ac(66px, 51px);

		img {
			object-position: left;
			object-fit: contain;
		}
	}
	
	&__title {
		margin-top: ac(21px, 14px);
		margin-bottom: ac(42px, 15px);
	}

	&__subtitle, &__descr {
		color: var(--white);
	}

	&__descr {
		margin-bottom: ac(61px, 40px);
		margin-top: ac(119px, 70px, 1024, 1440);
		max-width: 410px;
		width: 100%;
		overflow: hidden;

		p {
			font-weight: 600;
			font-size: ac(23px, 20px);
			line-height: ac(28px, 24px);
		}

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: ac(292px, 350px);
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--pastel);
			opacity: 1;
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	&__text {
		padding-right: 10px;

		& > * {
			&:not(:last-child) {
				margin-bottom: ac(30px, 21px);
			}
		}

		ul {
			margin-top: .5em;
			padding-left: 22px;


			li {
				font-size: 15px;
				line-height: 21px;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					left: -14px;
					top: 9px;
					border-radius: 100%;
					width: 4px;
					height: 4px;
					background-color: var(--white);
				}
			}
		}
	}


	@mixin tab {
		padding-top: 90px;

		&__content {
			flex-direction: column;

			&::before {
				height: 60%;
			}
		}

		&__col {
			max-width: 500px;
		}

		&__title-box {
			&::before {
				width: ac(350px, 310px, 375, 1024);
				height: ac(350px, 310px, 375, 1024);
				right: ac(57px, 70px, 375, 1024);
				top: ac(40px, 42px, 375, 1024);
			}
		}
	}

	@mixin tab-sm {
		&__btn-box {
			margin: auto 0 420px -160px;
		}

		&__wrapper {
			width: calc(100% + 38px);
		}
	}
}
