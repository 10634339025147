.banner {
	$gap: ac(241px, 80px);
	margin-top: $gap;

	&__wrapper {
		position: relative;
		padding: ac(42px, 75px) 30px ac(72px, 40px);
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		background-color: var(--tuna);
		border-radius: 30px;
		overflow: hidden;
	}

	&__bg {
		position: absolute;
		right: ac(0px, -65px, 1024, 1440);
		top: ac(-48px, -95px, 1024, 1440);
		width: ac(409px, 224px, 1024, 1440);
		height: ac(340px, 210px, 1024, 1440);

		img {
			object-fit: contain;
		}
	}

	&__subtitle,
	&__title {
		position: relative;
		color: var(--white);
		z-index: 1;
	}

	&__title {
		width: 100%;
		margin-bottom: 17px;
	}

	&__subtitle {
		margin-right: auto;
		width: calc(100% - ac(250px, 150px, 651, 1440));
		max-width: 770px;
		font-weight: 600;
		font-size: ac(23px, 20px);
		line-height: ac(28px, 24px);
	}

	&__btn {
		margin-bottom: -31px;
		z-index: 1;
	}

	@mixin mob-xl {
		&__subtitle {
			width: 100%;
		}

		&__btn {
			margin-top: 39px;
			margin-bottom: 0;
		}
	}
}
