.win {
	$gap: 0;
	margin-top: $gap;
}

.win--careers {
	$gap: ac(117px, 80px);
	margin-top: $gap;
}

.win {
	padding-top: ac(150px, 80px);
	padding-bottom: ac(80px, 40px);
	background-color: var(--tuna);
	border-radius: 30px 30px 0 0;

	&__wrapper {
		display: flex;
	}

	&__col {
		padding-left: ac(1px, 0px, 1024, 1440);
		flex: 0 0 auto;
		width: 100%;
		max-width: ac(603px, 470px, 1024, 1440);
		margin-right: ac(118px, 35px, 1024, 1440);
	}

	&__title,
	&__descr {
		color: var(--white);
	}

	&__title {
		padding-left: ac(2px, 0px, 1024, 1440);
	}

	&__descr {
		margin-top: ac(40px, 30px);
		font-size: ac(26px, 20px);
		line-height: 124%;
		font-weight: 600;
	}

	&__btn {
		margin-top: 29px;
	}

	&__video-box {
		position: relative;
		margin-top: 31px;
		z-index: 1;
	}

	&__decor {
		height: ac(480px, 400px, 1024, 1440);
		left: ac(-70px, -40px, 1024, 1440);
		position: absolute;
		top: ac(-118px, -80px, 1024, 1440);
		width: ac(480px, 400px, 1024, 1440);
		z-index: -1;

		img {
			object-fit: contain;
		}
	}

	@mixin tab {
		&__wrapper {
			flex-direction: column;
			align-items: center;
		}

		&__col {
			max-width: unset;
			margin-right: 0;
		}

		&__video-box {
			margin-top: 112px;
			max-width: 650px;
		}

		&__decor {
			height: ac(500px, 240px, 320, 1024);
			left: ac(-80px, -20px, 320, 1024);
			top: ac(-100px, -60px, 320, 1024);
			width: ac(500px, 240px, 320, 1024);
		}
	}
}
