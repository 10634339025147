.map-section {
    $gap: 0;
    margin-top: $gap;
    position: relative;
    padding-top: ac(151px, 60px);

    &-text-content {
        width: 70%;
        max-width: 809px;
        position: relative;
        z-index: 1;
        pointer-events: none;
    }

    .title {
        margin-bottom: 40px;
        pointer-events: all;
        max-width: 92%;
    }

    &-motto {
        font-size: ac(26px, 22px);
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 40px;
        max-width: 603px;
        width: 80%;
        pointer-events: all;
    }

    &-labels {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 60px;
        pointer-events: all;
    }
}

.slider-nav {
    margin-top: 20px;
    display: flex;
    user-select: none;
    
    .arrow-btn {
        margin: 0 10px;
        transform: rotate(15deg);
        width: ac(48px, 37px);
        height: ac(48px, 37px);

        span {
            line-height: ac(48px, 37px);
            font-size: ac(28px, 22px);
            transform: rotate(-15deg);
        }
    }

}
.map-swiper-wr {
    pointer-events: all;
    min-height: ac(350px, 100px);

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        height: auto;
    }

    .positions__card {
        max-width: 100%;
        width: 397px;
        margin: 0 auto;
        height: 100%;
    }

    .arrow-btn {
        margin-bottom: 20px;
    }
    .swiper{
        padding-bottom: 2px;
    }


}

.map-section-map-wr {
    width: perc(665);
    max-width: 730px;
    position: absolute;
    left: 53.8%;
    top: 0;
    transform: translate(0, -10.5%);

    &::before {
        content: '';
        display: block;
        padding-top: 159.39%;
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.active, &:hover {
        .map-pin {
            opacity: 0.5;
        }
    }

    .map-pin {
        cursor: pointer;
        transition: opacity 0.3s;

        &.active {
            opacity: 1 !important;
        }
    }

}

.map-hint {
    position: absolute;
    max-width: 500px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    text-align: center;
    font-size: ac(25px, 18px);
    font-weight: 600;

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: none;
    }
}

.map {
    &__wrapper {
        position: absolute;
        right: -54px;
        top: 89px;

        @mixin min-media 1920 {
            right: 5vw;
        }

        @mixin min-media 2120 {
            right: 10vw;
        }

        @mixin min-media 2400 {
            right: 15vw;
        }
    }

    &__container {
        width: 664px;
        height: 1060px;

        svg {
            height: ac(1430px, 880px, 993, 1440);;
            left: ac(-800px, -250px, 993, 1440);
            -o-object-fit: contain;
            object-fit: contain;
            position: absolute;
            top: ac(-513px, -113px, 993, 1440);
            width: ac(2152px, 1300px, 993, 1440);
        }

        .marker {
            cursor: pointer;
            clip-path: url(#map-pin-clip-path);
            transition: opacity .3s ease;
        }

        &.active {
            .marker {
                opacity: 0.4;

                &.active {
                    opacity: 1;
                }

                &.is-slider {
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .map {
        &__wrapper {
            position: relative;
            right: 0;
            top: 60px;

            @mixin mob-sm {
                top: 50px;
            }
        }

        &__container {
            width: 100%;
            height: ac(900px, 400px, 320, 992);

            svg {
                top: ac(-565px, -190px, 320, 993);
                left: ac(-600px, -356px, 320, 993);
                width: ac(2300px, 1000px, 320, 993);
                height: ac(1500px, 700px, 320, 993);
            }
        }
    }

    .map-section {
        &-map-wr {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            margin: ac(-280px, -100px, 320, 992) auto 0;
            width: 108%;
            transform: translateX(-4%);
        }

        &-text-content {
            max-width: 100%;
            width: 100%;
        }
    }

    .map-swiper-wr {
        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            max-width: fit-content;
        }
    }
}


@media (max-width: 768px) {
    .map-section {
        .title {
            max-width: 100%;
        }

        &-motto {
            max-width: 100%;
            width: 100%;
        }
    }

}

