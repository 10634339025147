.sg-form-section {
    $gap: 0;
    margin-top: $gap;
}

.sg-form {
    width: 100%;
    background: var(--tuna);
    border-radius: 30px;
    padding: ac(60px, 30px) ac(103px, 20px);

    .choices__list--single {
        .choices__item.choices__item--selectable {
            min-height: 48px;
            line-height: 24px;
            display: inline-flex;
            align-items: center;
        }
    }

    &__download-box {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        row-gap: 15px;
        margin-top: 15px;
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 3 - 15px);
        margin-right: 15px;
        background-color: var(--pastel);
        border-radius: 7px;
        padding: ac(40px, 20px) ac(30px, 15px);
        min-height: ac(209px, 170px);
        transition: .3s ease;

        .btn {
            margin-top: auto !important;
            border: 0;

            .arrow-btn {
                background-color: var(--pastel);

                span {
                    transform: rotate(135deg);
                }
            }

            &:hover {
                .arrow-btn {
                    background-color: var(--blue);
                }
            }
        }
    }

    &__download-title {
        font-size: ac(25px, 23px);
        line-height: ac(30px, 26px);
        font-weight: 600;
        letter-spacing: .08px;
        margin-bottom: 10px;
        transition: .3s ease;
    }

    &-title {
        font-size: ac(31px, 30px);
        margin-bottom: 40px;
        color: var(--blue);
        line-height: 1.2;
    }

    &-fields-wr {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 54px 6px 7px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--blue);
        z-index: 1;
        margin-bottom: ac(70px, 35px);
    }

    .choices {
        border-left: 1px solid var(--tuna);
        width: 33.3%;
        flex-shrink: 0;
        position: relative;

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 5px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }

        &:first-of-type {
            border: none;
        }
    }

    .choices__inner {
        padding-left: 22px;
        padding-right: ac(52px, 22px);
    }

    &-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;

        .btn{
            align-items: center;
            font-size: ac(15px, 14px);
            line-height: ac(29px, 20px);
        }
    }

    @mixin tab {
        &__download {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin mob-lg {
        &__download {
            width: calc(100% - 15px);
        }
    }
}

@media (max-width: 768px) {
    .sg-form-fields-wr {
        flex-direction: column;
        padding-right: 80px;

        .choices {
            width: 100%;
            border: none;
            border-bottom: 1px solid var(--tuna) !important;

            &:last-of-type {
                border: none !important;
            }
        }

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}