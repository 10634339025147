@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?sigpt9');
	src:  url('../fonts/icomoon.eot?sigpt9#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon.ttf?sigpt9') format('truetype'),
	  url('../fonts/icomoon.woff?sigpt9') format('woff'),
	  url('../fonts/icomoon.svg?sigpt9#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-search:before {
	content: "\e90f";
  }
  .icon-cross:before {
	content: "\e90e";
  }
  .icon-arrow-left:before {
	content: "\e90a";
  }
  .icon-arrow-right:before {
	content: "\e90b";
  }
  .icon-arrow-top:before {
	content: "\e90d";
  }
  .icon-arrow-bottom:before {
	content: "\e901";
  }
  .icon-arrow:before {
	content: "\e900";
  }
  .icon-twitter:before {
	content: "\e902";
  }
  .icon-linkedin:before {
	content: "\e903";
  }
  .icon-facebook:before {
	content: "\e904";
  }
  .icon-plus:before {
	content: "\e905";
  }
  .icon-misson-4:before {
	content: "\e906";
  }
  .icon-misson-3:before {
	content: "\e907";
  }
  .icon-misson-2:before {
	content: "\e908";
  }
  .icon-misson-1:before {
	content: "\e909";
  }
  .icon-play:before {
	content: "\e90c";
  }

h1,
h2,
h3 {
	font-weight: 700;
}

h1 {
	font-size: ac(70px, 40px);
	line-height: ac(86px, 50px);
}

h2 {
	font-size: ac(43px, 30px);
	line-height: ac(52px, 40px);
}

h3 {
	font-size: ac(31px, 28px);
	line-height: ac(40px, 34px);
}

a {
	display: inline-block;
	color: var(--tuna);
	transition: 0.3s ease;

	&:hover {
		color: var(--blue);
	}
}

ul {
	li {
		font-size: 15px;
		line-height: 21px;

		&:not(:last-child) {
			margin-bottom: ac(11px, 4px);
		}
	}
}

@mixin mob-sm {
	h1 {
		font-size: 36px;
	}

	h2 {
		font-size: 28px;
		line-height: 36px;
	}
}
