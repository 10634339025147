.details {
    $gap: ac(60px, 30px);
    margin-top: $gap;
}

.details--article {
    $gap: ac(70px, 30px);
    margin-top: $gap;
}

.details {
    &--article {
        .details {
            &__soc-box {
                margin-top: ac(80px, 30px);
            }
        }
    }

    &__wrapper {
        display: flex;
        margin-bottom: ac(151px, 75px);
    }

    &__col {
        max-width: ac(809px, 610px, 1024, 1440);
        margin-right: ac(118px, 35px, 1024, 1440);
        flex: 0 0 auto;

        .btn {
            margin-top: ac(29px, 20px);
        }
    }

    &__text-box {
        a {
            color: var(--pastel);

            &:hover {
                color: var(--blue);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(21px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(39px, 20px);
            }
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4 {
            font-size: ac(30px, 26px);
            line-height: ac(40px, 36px);
        }
        
        h5 {
            font-size: ac(28px, 24px);
            line-height: ac(38px, 34px);
        }
        
        h6 {
            font-size: ac(23px, 22px);
            line-height: ac(28px, 26px);
        }

        p, li {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            li {
                position: relative;
                padding-left: 24px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                    font-weight: 500;
                }
            }
        }
    }

    &__soc-box {
		display: flex;
		flex-direction: column;
        margin-top: ac(41px, 30px);

		& > span {
			width: 100%;
			font-weight: 600;
			font-size: ac(18px, 16px);
			line-height: ac(22px, 19px);
		}
	}

    &__soc-row {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		margin-right: ac(-20px, -18px);
		margin-bottom: -10px;
	}

    &__soc {
		display: flex;
		justify-content: center;
		width: ac(40px, 35px);
		height: ac(40px, 35px);
		background-color: var(--blue);
		border-radius: 7px;
		margin-right: ac(20px, 18px);
		margin-bottom: 10px;

		span {
			font-size: ac(23px, 21px);
			line-height: ac(40px, 35px);
			transition: .3s ease;

			&.icon-twitter {
				font-size: ac(21px, 18px);
			}
		}

		&:hover {
			background-color: var(--pastel);

			span {
				color: var(--tuna);
			}
		}
	}

    &__sticky {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__photo {
        position: relative;
        width: 287px;
        height: ac(191px, 164px, 1024, 1440);
        overflow: hidden;
        border-radius: 30px;

        img {
            border-radius: 30px;
        }

        .details__soc {
            position: absolute;
            bottom: ac(20px, 15px);
            left: ac(20px, 15px);
            margin: 0;
        }
    }

    &__name {
        margin-top: 20px;
        font-weight: 600;
        font-size: ac(23px, 18px);
        line-height: ac(28px, 24px);
    }

    &__position {
        margin-top: 10px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    &__link {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__bottom {
        border-radius: 30px 30px 0 0;
        background-color: var(--lily);
        padding: ac(150px, 80px) 0;
    }

    &__slider-box {
        display: flex;
        margin-top: ac(39px, 25px);
    }

    &__slider {
        width: 100% !important;
    }

    &__slide {
        .positions__card {
            height: 100%;
            width: 100%;
        }
    }

    @mixin tab {
        &__sidebar {
            margin-bottom: ac(40px, 20px);
        }

        &__wrapper {
            flex-direction: column-reverse;
            margin-top: ac(60px, 35px);
        }

        &__photo {
            border-radius: 5px;
            width: 100%;
            max-width: ac(403px, 340px, 375, 1024);
            height: ac(191px, 164px, 375, 1024);
        }

        &__col {
            margin-right: 0;
            max-width: 100%;
        }
    }
}