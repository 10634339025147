.prioritise {
    $gap: ac(150px, 80px);
    margin-top: $gap;
   
    .slider-nav {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
        user-select: none;
        
        .arrow-btn {
            margin: 0 5px;
            transform: rotate(15deg);
            width: ac(48px, 37px);
            height: ac(48px, 37px);

            span {
                line-height: ac(48px, 37px);
                font-size: ac(28px, 22px);
                transform: rotate(-15deg);
            }
        }

    }

    &__title {
        max-width: 910px;
        margin-bottom: ac(60px, 30px);
    }

    &__list {
        display: flex;
        width: 100%;
    }

    &__slider {
        overflow: visible !important;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 40px 20px 20px;
        border-radius: 19px;
        background-color: var(--pastel);
    }

    &__icon {
        font-size: 50px;
        color: var(--white);
    }

    &__descr {
        font-weight: 600;
        font-size: 18px;
        line-height: 124%;
        margin-top: 15px;
        text-align: center;
    }
}