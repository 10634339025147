.contact-form {
    $gap: 0;
    margin-top: $gap;
    z-index: 1;

    .submit-btn {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 23px;
        flex-shrink: 0;
    
        span {
            padding-right: 20px;
            transition: color 0.3s;
        }
    
        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 7px;
            background: var(--tuna);
            color: var(--white);
            font-size: ac(22px, 18px);
            transition: 0.3s;
        }
    
        &:hover {
            color: var(--blue) !important;
    
            i {
                color: var(--white) !important;
                background: var(--blue) !important;
            }
        }

        &.submit-white{
            color: var(--white);
            .icon-arrow{
                background: var(--white);
                color: var(--tuna);
            }
        }
    }

    &__input-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
        &--hidden {
            opacity: 0;
            transform-origin: 0 0;
            transform: scale(0);
            transition: .3s ease;
            max-height: 0;
    
            &.active {
                max-height: 100px;
                transform: scale(1);
                opacity: 1;
            }
        }
    
        &--alt {
            .choices {
                width: 100%;
                margin: 0 !important;
    
                &::before {
                    content: '';
                    position: absolute;
                    right: ac(20px, 15px);
                    top: ac(25px, 22px);
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid var(--tuna);
                    border-bottom: 2px solid var(--tuna);
                    transform: rotate(45deg);
                    transition: .3s ease;
                }
    
                &.is-open {
                    &::before {
                        transform: rotate(-135deg);
                    }
                }
    
                .choices__input--cloned {
                    border: 0;
                    border-bottom: 1px solid var(--blue);
                    border-radius: 0;
                }
    
                &__inner {
                    padding: 0 ac(48px, 30px) 0 ac(30px, 15px);
                    border: 1px solid var(--blue);
                    border-radius: 7px;
                    display: flex;
                    min-height: ac(62px, 57px);
                    align-items: center;
    
                }
    
                &__list--single {
                    .choices__item {
                        color: var(--tuna) !important;
                        font-weight: 300;
                        font-size: 16px;
                        border: 0;
                    }
    
                    .choices__placeholder {
                        color: rgba(43, 50, 69, 0.5);
                    }
                }
            }
        }
    
        span {
            padding-bottom: 12px;
            font-size: 16px;
            font-weight: 500;
            b{
                color: var(--red);
            }
        }
    
        input, textarea {
            width: 100%;
            padding: 18px ac(30px, 15px);
            border: 1px solid var(--blue);
            border-radius: 7px;
            color: var(--tuna);
            font-weight: 300;
            font-size: 16px;
    
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
                -webkit-text-fill-color: inherit !important;
            }
    
            &::placeholder {
                color: inherit;
                font-weight: inherit;
                font-size: inherit;
                font-family: inherit;
            }
        }
        textarea{
            resize: none;
            height: 124px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px 15px;

        .btn-wr {
            display: flex;
        }
    }

    &__caption {
        display: flex;
        align-items: center;
        font-size: ac(24px, 22px);
        margin-bottom: 49px;

       span  {
            font-weight: 700;
            line-height: normal;
            font-size: inherit;
        }

        .choices {
            margin-top: 2px;
            flex-grow: 1;
            max-width: 303px;

            .choices__inner {
                min-height: unset;
            }
        }

        @mixin mob-lg {
            align-items: flex-start;
            flex-direction: column;

            .choices {
                max-width: 100%;
                width: 100%;
                margin: 10px 0  0!important;
            }
        }
    }

    textarea {
        height: 124px;
        resize: none;
    }

    .submit-btn {
        margin-top: 3px;
    }

    @media (max-width: 1023px) {
        .contact-form__grid {
            grid-template-columns: 1fr;
        }
    }
}


.contact-form {
    position: relative;
    $gap: 0;
    padding-top: $gap;

    &__form {
        flex-grow: 1;
        max-width: 66.2%;
        min-height: 525px;

        @media (max-width: 650px) {
            min-height: auto;
            max-width: 100%;
            width: 100%;
        }
    }

    &__caption {
        .choices {
            margin-left: 9px;
            margin-right: 9px;

            &:nth-of-type(1) {
                min-width: ac(174px, 134px, 320, 1440);
            }

            &:nth-of-type(2) {
                min-width: ac(281px, 174px, 650, 1440);
            }

            &__inner {
                padding-left: 0;
            }

            .choices__list--single {
                .choices__item {
                    font-weight: 700;
                    font-size: ac(24px, 22px);
                    line-height: ac(34px, 28px);
                    padding: 0;
                    border-bottom: 2px solid var(--blue);
                }

                .choices__placeholder {
                    color: rgba(43, 50, 69, 0.5);
                }
            }

            .choices__list--dropdown {
                .choices__item {
                    padding: 5px 10px;
                    font-weight: 400;
                    font-size: ac(24px, 20px);
                    line-height: ac(32px, 28px);

                    &::after {
                        display: none;
                    }
                }
            }
        }

    }

    &__thumbnail {
        width: perc(627.4);
        position: absolute;
        right: -11.7%;
        top: -119px;
        max-width: 700px;

        @mixin min-media 1800 {
            right: 0;
        }

        @mixin min-media 2300 {
            right: 150px;
            max-width: 750px;
        }

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media (max-width: 650px) {
            display: none;
        }
    }
}