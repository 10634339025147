.testimonials {
    padding: ac(109px, 20px) 0 ac(87px, 40px);
    $gap: ac(242px, 80px);
    margin-top: $gap;
    position: relative;
    border-radius: 30px;
    z-index: 1;

    &::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 85%;
		height: 100%;
		border-radius: 30px;
		background-color: var(--pastel);
		z-index: -1;
	}

    &__bg {
		position: absolute;
		top: calc(50% + 15px);
		right: ac(-259px, -145px, 1024, 1440);
		height: ac(570px, 300px, 1024, 1440);
		width: ac(570px, 300px, 1024, 1440);
		transform: translateY(-50%);
        z-index: -1;

		img {
			object-fit: contain;
		}
	}

    &__slider {
        max-width: 1100px;
        overflow: visible !important;
    }

    &__slide {
        position: relative;
        display: flex;
        opacity: 0;
        transition: .3s ease;

        &.swiper-slide-active {
            opacity: 1;
        }
    }

    &__img {
        flex: 0 0 auto;
        position: absolute;
        top: ac(-198px, -120px, 900, 1440);
        left: ac(-255px, -180px, 900, 1440);
        height: ac(610px, 290px);
        max-width: ac(610px, 290px);
        width: 100%;

        img {
            position: absolute;
        }
    }

    &__mask {
        width: 95%;
        height: 95%;
        clip-path: url(#testimonials-mask-1);
		overflow: hidden;
    }

    &__content {
        margin-left: ac(455px, 300px, 900, 1440);
        max-width: ac(500px, 470px, 1024, 1440);
        color: var(--white);
    }

    &__quotes {
        font-size: 58px;
        font-family: 'Arial', sans-serif;
        margin-bottom: 4px;
        display: block;
    }

    &__descr {
        font-size: ac(23px, 20px);
        line-height: ac(28px, 26px);
        font-weight: 500;
        margin-bottom: ac(42px, 20px);
    }

    &__name {
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 600;
    }

    &__pos {
        font-size: 15px;
        font-weight: 500;
    }

    @mixin tab-md {
        &::before {
            width: 100%;
        }

        &__bg {
            display: none;
        }

        &__slide {
            flex-direction: column;
            align-items: center;
        }

        &__img {
            position: relative;
            top: 0;
            left: 0;
        }
    
        &__content {
            margin: 40px 0 0;
            padding-top: 0;
        }
      
    }
}


