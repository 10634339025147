.follow-us {
    $gap: ac(151px, 75px);
    margin-top: $gap;

    &__title {
        margin-bottom: ac(39px, 25px);
    }

    &__tabs {
		margin-top: ac(38px, 20px);
		display: flex;
		flex-wrap: wrap;
		margin-top: -15px;
		margin-right: -16px;
		margin-bottom: ac(35px, 30px);
	}

	&__tab {
		position: relative;
		font-weight: 600;
        font-size: 15px;
        line-height: 19px;
		margin-right: 16px;
		margin-top: 15px;
		transition: .3s ease;
		user-select: none;
		cursor: pointer;
		
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -6px;
			width: 0%;
			height: 2px;
			background-color: var(--tuna);
			transition: .3s ease;
		}

		&:hover, &.active {
			color: var(--blue);
		}

		&.active {
			&::before {
				width: 100%;
			}
		}
	}

    &__tab-content {
        display: none;

        &.active {
            display: block;
        }
    }

    &__slider-box {
        display: flex;
    }

    &__slider {
        width: 100% !important;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #FAFAFA;
        border-radius: 8px;
        padding: 20px;
        height: 100%;
        overflow: hidden;
        border: 1px solid #FAFAFA;
        transition: .3s ease;

        &:hover {
            border-color: var(--blue);
        }
    }

    &__date {
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
    }

    &__soc {
        display: flex;
        justify-content: center;
        width: 17px;
        height: 17px;
        background-color: #086EA7;
        border-radius: 4px;

        span {
            font-size: 9px;
            line-height: 17px;
            color: var(--white)
        }
    }

    &__descr {
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 18px;
    }

    &__img {
        width: calc(100% + 40px);
        margin: auto 0 -20px -20px;
        height: ac(150px, 120px);
    }
}