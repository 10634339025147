.trending {
    $gap: ac(150px, 70px);
    margin-top: $gap;
    padding: ac(150px, 60px) 0;
    border-radius: 30px 30px 0 0;
    background-color: var(--pastel);

    &__title-box {
		display: flex;
		flex-direction: column;
	}

	&__search {
		position: relative;
        margin-top: ac(39px, 25px);
        max-width: 500px;
		width: 100%;
	}

	&__input {
		width: 100%;
		padding: 7px 60px 7px ac(30px, 15px);
		background: var(--white);
		border: 1px solid var(--blue);
		border-radius: 7px;
		line-height: ac(46px, 35px);
	}

	&__btn {
		position: absolute;
		right: 7px;
		top: 7px;
        width: ac(48px, 37px);
        height: ac(48px, 37px);

        .icon-arrow {
            line-height: ac(48px, 37px);
            font-size: 21px;
        }
	}

    &__card-box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
		margin-top: ac(59px, 30px);
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: ac(41px, 20px) ac(37px, 15px) ac(41px, 20px) ac(30px, 15px);
        margin-right: ac(67px, 20px);
        width: calc(100% / 3 - 15px - ac(67px, 20px));
        min-height: ac(307px, 240px);
        border-radius: 30px 0 30px 30px;
        background-color: var(--white);
        transition: color .3s ease, transform .4s ease !important;
        cursor: pointer;

        .btn {
            margin-top: auto;
        }

        &:nth-child(1) {
            .trending__icon {
                right: ac(-40px, -15px, 320, 1440);
                top: ac(45px, 13px, 320, 1440);
                width: ac(65px, 30px, 320, 1440);
                height: ac(65px, 30px, 320, 1440);
            }
        }

        &::before {
			content: '';
			position: absolute;
			right: 1px;
			top: 0;
			transform: translateX(100%);
			width: ac(67px, 20px);
			height: ac(142px, 50px);
			background-color: var(--white);
			border-radius: 0 30px 30px 0;
			transition: .3s ease;
		}
    }

    &__title {
        margin-bottom: ac(20px, 10px);
        font-weight: 700;
        font-size: ac(31px, 26px);
        line-height: ac(38px, 34px);
    }

    &__descr {
        margin-bottom: ac(40px, 20px);
        font-weight: 500;
        font-size: ac(18px, 16px);
        line-height: ac(22px, 20px);
        max-height: 110px;
        @mixin max-line-length 5;
    }

    &__icon {
        position: absolute;
        right: ac(-30px, -8px, 320, 1440);
        top: ac(45px, 13px, 320, 1440);
        width: ac(55px, 25px, 320, 1440);
        height: ac(55px, 25px, 320, 1440);

        img {
            object-position: right top;
            object-fit: contain;
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px - ac(67px, 20px));
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px - ac(67px, 20px));
        }
    }

    @mixin mob {
        &__title {
            max-width: 95%;
        }
    }
}