.benefits {
    $gap: ac(150px, 80px);
    padding-top: $gap;

    &__wrapper {
        display: flex;

        @mixin tab {
            flex-direction: column;
        }
    }

    &__img {
        flex: 0 0 auto;
        max-width: 397px;
        width: 100%;
        margin-right: ac(118px, 20px, 1024, 1440);

        img {
            border-radius: 30px
        }

        @mixin aspect-ratio 387, 668;

        @mixin tab {
            height: ac(600px, 350px);
            max-width: ac(500px, 300px);
            margin-bottom: 40px;
        }
    }

    &__col {
        position: relative;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: ac(-40px, 0px);
            left: ac(-55px, -20px);
            width: ac(585px, 400px);
            height: ac(585px, 400px);
            border-radius: 80px;
            transform: rotate(-15deg);
            background-color: var(--lily);
            z-index: -1;
        }
    }

    &__title {
        max-width: 603px;
        margin-bottom: ac(43px, 20px);
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px ac(118px, 20px, 1024, 1440);

        @mixin mob-lg {
            grid-template-columns: 1fr;
        }
    }

    &__item {
        max-width: 294px;

        @mixin tab {
            max-width: 100%;
        }
    }

    &__icon {
        max-width: 70px;
        height: 70px;
        margin-bottom: 15px;

        img {
            object-fit: contain;
            object-position: left;
        }
    }

    &__name {
        font-weight: 600;
        font-size: ac(23px, 20px);
        line-height: 120%;
        margin-bottom: 21px;
    }

    &__desc {
        font-weight: 500;
        font-size: 15px;
        line-height: 145%;

        &[data-simplebar] {
            max-height: 178px;
            padding-right: 15px;
            margin-right: -15px;
        }
    }
}