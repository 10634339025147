.first {
	$gap: 0;
	padding-top: ac(155px, 120px, 1366, 1440);
	margin-top: $gap;

	.container {
		height: 100%;
	}

	&--home {
		padding-top: ac(210px, 140px);
		padding-bottom: 41px;
		border-bottom: 1px solid var(--pastel);

		.first {
			&__btn-box {
				display: flex;
				margin-top: ac(122px, 80px);
				margin-right: ac(-122px, -150px);
				transform: translateX(ac(64px, 20px, 651, 1440));
			}

			&__btn {
				position: relative;
				max-width: ac(150px, 140px, 651, 1440);
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				margin-right: ac(122px, 60px, 651, 1440);

				.arrow-btn {
					margin-top: auto;
					transform: rotate(29deg);
					width: ac(40px, 37px);
					height: ac(40px, 37px);
					border-radius: 3px;
					pointer-events: none;

					span {
						line-height: ac(40px, 37px);
						font-size: ac(28px, 22px);
						transform: rotate(-30deg) translate(1px, 1px);
					}
				}

				&>span {
					font-weight: 600;
					font-size: ac(23px, 16px);
					line-height: ac(28px, 20px);
					margin-bottom: ac(25px, 10px);
					text-align: center;
					transition: .3s ease;
				}

				&::before {
					content: '';
					position: absolute;
					top: ac(0px, 8px);
					left: ac(0px, 30px);
					width: ac(220px, 120px);
					height: ac(220px, 120px);
					background-color: var(--blue);
					border-radius: 30px;
					transform: rotate(-60deg) translate(20px, -55px);
					z-index: -1;
					transition: .3s ease;
				}

				&--blue {
					&::before {
						background-color: var(--pastel);
					}
				}

				&--dark {
					&::before {
						background-color: var(--tuna);
					}

					.arrow-btn {
						background-color: var(--white);

						span {
							color: var(--tuna);
						}
					}

					&>span {
						color: var(--white);
					}

					&:hover {
						&>span {
							color: var(--white) !important;
						}
					}
				}

				&:hover {
					&::before {
						opacity: .7;
					}

					&>span {
						color: var(--tuna);
					}
				}
			}

			&__img-box {
				align-items: center;
				bottom: 79%;
				display: flex;
				justify-content: center;
				position: absolute;
				right: ac(210px, 45px);
			}
		
			&__img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: ac(280px, 80px);
				height: ac(280px, 80px);
				margin-right: 20px;
				margin-bottom: 20px;
				background-color: var(--blue);
				clip-path: url(#first-mask-1);
				overflow: hidden;
		
				&:nth-child(1) {
					background-color: var(--tuna);
					transform: translate(0px, ac(0px, -93px));
				}
		
				&:nth-child(2) {
					transform: translate(ac(232px, 112px), ac(134px, 64px));
					background-color: var(--tuna);
				}
		
				&:nth-child(3) {
					transform: translate(ac(97px, 10px), ac(364px, 340px));
					
				}
		
				&:nth-child(4) {
					transform: translate(ac(-97px, -40px), ac(-364px, -260px));
				}
		
				&:nth-child(5) {
					transform: translate(ac(-133px, -50px), ac(231px, 306px));
				}
		
				&:nth-child(6) {
					transform: translate(ac(133px, 20px), ac(-231px, -224px));
				}
		
				&:nth-child(7) {
					transform: translate(ac(-232px, -60px), ac(-134px, -130px));
				}

				&.home-img-scale {
					img {
						object-position: 50% 20%;
					}
				}
			}

			@mixin mob-xl {
				&__btn-box {
					flex-direction: column;
					align-items: center;
					margin-right: 0;
				}

				&__btn {
					margin-right: 0;
					max-width: 120px;

					&:not(:last-child) {
						margin-bottom: ac(120px, 80px, 375, 651);
					}

					&::before {
						transform: rotate(-60deg) translate(15px,-50px);
					}
				}
			}
		}
	}

	&--about {
		position: relative;
		z-index: 1;

		.first {
			&__wrapper {
				display: block;
			}

			&__head {
				background-color: var(--tuna);
				border-radius: 30px;
				padding: 60px 0;
			}

			&__title {
				margin-top: 20px;
				width: 100%;
				max-width: ac(809px, 360px);
				color: var(--white);

				span {
					color: var(--blue);
				}
			}

			&__img-box {
                position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: ac(103px, 150px, 1024, 1440);
				bottom: ac(-200px, -150px, 1024, 1440);
            }

            &__img {
                position: absolute;
                width: ac(281px, 150px, 1024, 1440);
                height: ac(281px, 150px, 1024, 1440);
                overflow: hidden;
				clip-path: url(#first-mask-3);
				transform: translate(ac(0px, 60px, 1024, 1440), ac(0px, -110px, 1024, 1440));

				&:nth-child(1) {
					transform: translate(ac(-97px, 10px, 1024, 1440), ac(-364px, -303px, 1024, 1440));
				}

				&:nth-child(2) {
					transform: translate(ac(-230px, -60px, 1024, 1440), ac(-133px, -180px, 1024, 1440));
				}

				&:nth-child(3) {
					transform: translate(134px, -231px);
				}

				&:nth-child(5) {
					transform: translate(ac(-134px, -13px, 1024, 1440), ac(231px, 10px, 1024, 1440));
				}
            }

            &__award {
                position: absolute;
                bottom: ac(-152px, 5px, 1024, 1440);
                left: ac(-298px, -135px, 1024, 1440);
                height: ac(240px, 140px, 1024, 1440);
                width: ac(154px, 95px, 1024, 1440);
                z-index: 1;

                img {
                    object-fit: contain;
                }
            }

			&__text-box {
				margin-top: ac(150px, 20px);
				max-width: ac(809px, 500px);

				h3 {
					font-weight: 600;
					margin-top: ac(39px, 15px);
					font-size: ac(26px, 18px);
					line-height: ac(32px, 24px);
				}
			}

			&__descr {
				margin-top: ac(20px, 10px);
				font-weight: 600;
				font-size: ac(23px, 16px);
				line-height: ac(28px, 22px);
			}
		}
	}

	&--org,
	&--prof {
		margin-top: 154px;
		border-radius: 30px;
		padding: 60px 0;
		background-color: var(--tuna);

		.first {
			&__wrapper {
				position: relative;
				flex-direction: column;
			}

			&__title {
				margin-top: 20px;
				width: 100%;
				max-width: ac(809px, 360px, 500, 1440);
				color: var(--white);

				span {
					color: var(--blue);
				}
			}

			&__img {
                position: absolute;
                overflow: hidden;
				
				&:nth-child(1) {
					clip-path: url(#first-mask-5);
					width: ac(292px, 120px, 500, 1440);
					height: ac(292px, 120px, 500, 1440);
					right: ac(-64px, 20px, 500, 1440);
					top: ac(-90px, -125px, 500, 1440);
				}
				
				&:nth-child(2) {
					clip-path: url(#first-mask-4);
					width: ac(375px, 120px, 500, 1440);
					height: ac(375px, 120px, 500, 1440);
					right: ac(30px, -35px, 500, 1440);
					top: ac(60px, -65px, 500, 1440);
				}
            }

			&__btn-box {
				display: flex;
				flex-wrap: wrap;
				margin-top: 40px;
				margin-bottom: -15px;

				.btn {
					margin-bottom: 15px;
					margin-right: 14px;
				}
			}
		}
	}

	&--prof {
		.first__img {
			&:nth-child(1) {
				right: ac(-70px, 20px, 500, 1440);

				img {
					object-position: 77%;
				}
			}

			&:nth-child(2) {
				right: ac(25px, -35px, 500, 1440);

				img {
					object-position: 82%;
					height: 109%;
				}
			}
		}
	}

	&--org {
		.first {
			&__img {
				&:nth-child(1) {
					clip-path: url(#first-mask-6);
					width: ac(292px, 120px, 500, 1440);
					height: ac(292px, 120px, 500, 1440);
					right: ac(114px, 20px, 500, 1440);
					top: ac(-89px, -125px, 500, 1440);
				}
				
				&:nth-child(2) {
					clip-path: url(#first-mask-7);
					width: ac(375px, 120px, 500, 1440);
					height: ac(375px, 120px, 500, 1440);
					right: ac(-80px, -35px, 500, 1440);
					top: ac(52px, -75px, 500, 1440);
				}
            }
		}
	}

	&--contact {
        padding-top: ac(216px, 146px);
        padding-bottom: 40px !important;
        height: auto !important;
        border-bottom: none;

        .first {
            &__wrapper {
                justify-content: space-between;
            }

            &__suptitle {
                display: inline-block;
                margin-bottom: 20px;
            }

            &__title {
                margin-bottom: 40px;

                span {
                    color: var(--blue);
                }

				> span {
					margin-right: ac(-20px, -8px);
				}
            }

            &__caption {
                max-width: 603px;
            }

            &__motto {
                p {
					font-weight: 500;
                    font-size: ac(18px, 16px);
                    line-height: 144%;

                    &:first-of-type {
                        font-size: ac(23px, 18px);
                        line-height: normal;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                }
            }

            &__contact-info {
                position: relative;
                display: flex;
                flex-direction: column;
				padding: 60px 90px;
				z-index: 1;

				a {
					margin-top: 15px;
					font-size: ac(24px, 16px);
					font-weight: 700;
				}

                &::before {
                    content: "";
                    width: 620px;
                    height: 620px;
                    position: absolute;
                    left: 74px;
                    top: -254px;
                    background: var(--lily);
                    border-radius: 80px;
					transform: rotate(-150deg);
					z-index: -1;
                }
            }
        }
    }

	&--member {
		position: relative;
		padding-top: ac(185px, 140px);

		.first__heading {
			max-width: 480px;
		}

		.first__title {
			margin-bottom: ac(30px, 15px);
		}

		.first__subtitle {
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			display: inline-block;
			margin-bottom: 15px;
			color: var(--pastel);
			padding-bottom: 6px;
			border-bottom: 2px solid var(--pastel);
		}

		.first__contacts {
			margin-bottom: ac(43px, 20px);

			&-link {
				display: block;
				width: fit-content;
				font-weight: 600;
				font-size: ac(23px, 18px);

				&:not(:first-child) {
					margin-top: ac(16px, 10px);
				}
			}
		}

		.first__descr {
			font-weight: 600;
			font-size: ac(23px, 20px);
			line-height: ac(28px, 26px);
		}

		.first__img {
			width: vw(712);
			height: vw(428);
			max-width: ac(712px, 460px, 1024, 1440);
			max-height: 428px;
			min-height: ac(375px, 300px);

			margin: 0;
			right: 0;
			left: unset;
			top: unset;
			bottom: 6px;
			position: absolute;
			border-radius: 30px;
			overflow: hidden;
			background-color: transparent !important;
		}

		.first__socials {
			bottom: 40px;
			right: ac(108px, 40px);
			position: absolute;

			&-link {
				width: ac(88px, 50px);
				height: ac(88px, 50px);
				background: var(--blue);
				font-size: ac(50px, 32px);
				border-radius: 7px;

				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background: var(--white);
				}
			}
		}
	}

	&--details {
        padding-top: ac(215px, 150px);
		overflow: hidden;

        .first {
            &__wrapper {
                flex-direction: column;

                .btn {
                    margin-top: ac(39px, 25px);
                }
            }

            &__title {
                margin-top: ac(20px, 15px);
            }

			&__bottom {
                margin-top: ac(41px, 30px);
				background-color: var(--tuna);
				width: 100%;
				border-radius: 30px;
				padding: ac(37px, 20px) ac(100px, 15px);
			}

            &__info-box {
                display: flex;
                flex-wrap: wrap;
                margin-right: ac(-155px, -20px);
                margin-bottom: -15px;
            }

            &__info {
                display: flex;
                flex-direction: column;
                margin-right: ac(155px, 20px);
                margin-bottom: 15px;

                span {
                    &:nth-child(1) {
                        font-size: 15px;
                        line-height: 19px;
                        color: var(--blue);
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        margin-top: 5px;
                        font-weight: 600;
                        font-size: ac(23px, 18px);
                        line-height: ac(30px, 24px);
						color: var(--white);
                    }
                }

                &:last-of-type {
                    @media (min-width: 1280px) {
                        margin-left: ac(25px, 0px);
                    }
                }
            }
        }
    }

	&--article {
        padding-top: ac(155px, 150px);

        .first {
            &__title {
                position: relative;
                color: var(--blue);
				background-color: var(--tuna);
				border-radius: 30px;
				padding: ac(60px, 25px) ac(104px, 15px);
                z-index: 1;
            }

            &__wrapper {
                margin-top: 90px;
                display: flex;
				flex-direction: column;
            }

            &__col {
				margin-top: ac(30px, 20px);
                flex: 0 0 auto;
                width: 100%;
                max-width: 809px;
                margin-right: 118px;
                min-height: unset;
            }

            &__descr {
                margin-top: ac(100px, 40px);
                font-weight: 600;
                font-size: ac(26px, 22px);
                line-height: ac(32px, 30px);
            }

            &__info {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-right: ac(-38px, -15px);
                margin-bottom: -10px;

                span {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 19px;
                    margin-right: ac(38px, 15px);
                    margin-bottom: 10px;

                    &:first-child {
                        padding: 6px 11px;
                        border-radius: 7px;
                        background-color: var(--pastel);
                    }
                }
            }

            &__img-box {
                position: relative;
				bottom: unset;
				right: unset;
                margin-top: ac(-167px, -140px);
                width: 100%;
                height: ac(452px, 220px, 320, 1440);
            }

            &__img {
				left: 0;
				top: 0;
                width: 100%;
                height: 100%;
				background-color: transparent !important;

                img {
                    border-radius: 30px;
                }
            }
        }
    }

	&--blogs,
    &--category {
        padding-top: ac(215px, 150px);

        .first {
			&__wrapper {
                display: flex;
				width: 100%;
				flex-direction: column;
				position: relative;
            }

            &__title {
				max-width: ac(706px, 300px);
                color: var(--blue);
            }

            &__descr {
				margin-top: ac(39px, 15px);
				max-width: 603px;
                font-weight: 600;
                font-size: ac(26px, 22px);
                line-height: ac(32px, 30px);
            }

            &__icon {
				position: absolute;
				right: ac(62px, -10px, 768, 1440);
				top: ac(17px, -20px, 768, 1440);
                width: ac(162px, 82px, 768, 1440);
                height: ac(148px, 68px, 768, 1440);

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(-150deg);
					width: ac(230px, 110px, 768, 1440);
					height: ac(230px, 110px, 768, 1440);
					background-color: var(--lily);
					z-index: -1;
					border-radius: 30px;
				}

                img {
                    object-fit: contain;
                }
            }

			&__img-box {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				position: relative;
				margin-top: ac(60px, 30px);
				padding: ac(50px, 25px) ac(105px, 15px);
                width: 100%;
                min-height: ac(452px, 320px);
            }

            &__img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(180deg, rgba(8, 2, 35, 0.00) 0%, #080223 100%);
					border-radius: 30px;
				}

                img {
                    border-radius: 30px;
                }
            }

            &__bottom {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                max-width: 501px;
                text-align: right;

				h2 {
					color: var(--white);
				}
            }

            &__link {
                margin-top: ac(18px, 10px);
                display: flex;
                align-items: flex-end;
				color: var(--white);

                .icon-arrow {
                    font-size: 21px;
                }

                & > span {
                    &:nth-child(1) {
                        margin-right: 19px;
                        font-weight: 700;
                        font-size: ac(23px, 18px);
                        line-height: ac(28px, 26px);
                    }
                }

                .arrow-btn {
                    margin-bottom: 11px;
					transform: rotate(15deg);
					background-color: var(--white);

					span {
						transform: rotate(30deg);
						color: var(--tuna);
					}
                }

				&:hover {
					.arrow-btn {
						background-color: var(--blue);
					}
				}
            }
            

            &__decor {
                position: absolute;
				z-index: -1;

                img {
                    object-fit: contain;
                }

				&:nth-child(1) {
					height: ac(477px, 230px, 320, 1440);
					right: -79px;
					top: ac(-208px, -20px, 768, 1440);
					width: ac(527px, 250px, 320, 1440);
				}

				&:nth-child(2) {
					height: ac(333px, 150px, 320, 1440);
					left: ac(-113px, -50px, 320, 1440);
					top: ac(64px, 80px, 320, 1440);
					width: ac(333px, 150px, 320, 1440);
				}
            }
        }
    }

	&--category {
		padding-top: ac(215px, 175px);

		.first {
			&__icon {
				right: ac(62px, 5px, 320, 1440);
				top: ac(17px, -75px, 375, 1440);

				&::before {
					width: ac(230px, 95px, 768, 1440);
					height: ac(230px, 95px, 768, 1440);
				}
			}
		}

	}

	&--resources {
        padding-top: ac(215px, 150px);
        padding-bottom: 0;
        border-bottom: 0;
        height: auto;

        .first {
            &__grid {
                margin-top: ac(90px, 35px);

                &>h2 {
                    white-space: nowrap;
                }
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
            }

            &__title {
				max-width: 1015px;
                margin-top: 21px;

                span {
                    color: var(--blue);
                }
            }

            &__submit {
                right: 0px;
                top: -12px;
            }

            &__form {
                margin-top: ac(108px, 35px);
                border: 0;
                max-width: ac(980px, 860px, 1024, 1440);
                margin-left: 0;
                padding: 0;
            }

            &__select-box {
                display: flex;
                font-weight: 700;
                font-size: ac(24px, 20px);
                line-height: ac(30px, 26px);

                span {
                    flex: 0 0 auto;
                }

                .choices {
                    margin-left: 9px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        min-width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        min-width: ac(281px, 174px, 650, 1440);
                    }

                    &__inner {
                        padding-left: 0;
                    }

                    .choices__list--single {
                        .choices__item {
                            font-weight: 700;
                            font-size: ac(24px, 20px);
                			line-height: ac(30px, 26px);
                            padding: 0;
                            border-bottom: 2px solid var(--blue);
                            max-width: ac(300px, 225px, 1024, 1440);
                            max-height: ac(40px, 32px);
                            @mixin max-line-length 1;
                        }

                        .choices__placeholder {
                            color: rgba(43, 50, 69, 0.5);
                        }
                    }

					.choices__list--dropdown {
						.choices__item {
							padding: 5px 10px;
							font-weight: 400;
							font-size: ac(18px, 15px, 1024, 1440);
							line-height: 1.2;

							&::after {
								display: none;
							}
						}
					}
                }
            }
        }
    }

	&--positions {
        padding-top: ac(215px, 160px);
        padding-bottom: 0;
        border-bottom: 0;

        .first {
            &__wrapper {
                position: relative;
                flex-direction: column;
            }

            &__decor {
                position: absolute;
                right: ac(-9px, 0px, 320, 1440);
                top: ac(-84px, -110px, 320, 1440);
                width: ac(314px, 80px, 568, 1440);
                height: ac(410px, 160px, 320, 1440);
                z-index: -1;

                img {
                    object-fit: contain;
                }
            }

            &__title {
                margin-top: 21px;
				max-width: ac(1015px, 300px);
            }

            &__form {
                margin-top: ac(53px, 30px);
                margin-bottom: 14px;

                &--hidden {
                    margin-top: 0;
                    margin-bottom: 0;

					.choices {
                        min-width: ac(313px, 215px, 768, 1440);
                    }
                }
            }

            &__tags {
                margin-top: 14px;
            }
        }
    }

	&--careers {
        padding-top: ac(215px, 150px);
        padding-bottom: 32px;

        .first {
            &__wrapper {
                position: relative;

                & > .first__btn {
					position: absolute;
					right: ac(0px, -13px, 320, 1440);
					top: ac(-37px, -18px, 320, 1440);
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: var(--tuna);
					padding: ac(30px, 15px) ac(88px, 10px) ac(30px, 15px) ac(100px, 10px);
					border-radius: 30px;
                    z-index: 1;

                    span:not(.arrow-btn) {
                        font-weight: 700;
                        font-size: ac(23px, 14px);
                        line-height: ac(28px, 20px);
                        transition: 0.3s ease;

                        color: var(--white);
                    }

                    .arrow-btn {
                        width: ac(48px, 29px);
                        height: ac(48px, 29px);
						background-color: var(--blue);

						.icon-arrow {
							transition: .3s ease;
						}
                    }

					&:hover {
						.arrow-btn {
							background-color: var(--pastel);
							
							.icon-arrow {
								color: var(--white);
							}
						}
					}
                }
            }

            &__col {
                max-width: ac(707px, 550px, 1024, 1440);
                align-items: flex-start;
            }

            &__title {
                margin-top: ac(21px, 40px);

                span {
                    color: var(--blue);
                }
            }

            &__descr {
                margin-top: ac(60px, 25px);
                max-width: 499px;

                p {
                    &:first-child {
                        line-height: 26px;
                    }

                    &:not(:first-child) {
                        margin-top: ac(20px, 15px);
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
            }

            &__img-box {
                position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 174px;
				bottom: -47px;
            }

            &__img {
                position: absolute;
                width: ac(281px, 150px, 1024, 1440);
                height: ac(281px, 150px, 1024, 1440);
                overflow: hidden;
				clip-path: url(#first-mask-2);
				transform: translate(ac(0px, 60px, 1024, 1440), ac(0px, -110px, 1024, 1440));

				&:nth-child(1) {
					transform: translate(ac(-97px, 10px, 1024, 1440), ac(-364px, -303px, 1024, 1440));
				}

				&:nth-child(2) {
					transform: translate(ac(-230px, -60px, 1024, 1440), ac(-133px, -180px, 1024, 1440));
				}

				&:nth-child(3) {
					transform: translate(134px, -231px);
				}
            }

            &__award {
                position: absolute;
                bottom: ac(57px, 160px, 1024, 1440);
                left: ac(-438px, -210px, 1024, 1440);
                height: ac(240px, 140px, 1024, 1440);
                width: ac(154px, 95px, 1024, 1440);
                z-index: 1;

                img {
                    object-fit: contain;
                }
            }

            &__btn-box {
                margin-top: ac(110px, 10px);
                display: flex;
                flex-wrap: wrap;
                gap: 25px;
                margin-right: -25px;
            }

            &__btn {
                display: flex;
                align-items: flex-end;
                min-width: ac(284px, 227px);
                margin-right: 0;
                background-color: transparent;

                &:hover {
                    .arrow-btn {
                        background-color: var(--pastel);
						color: var(--tuna);
						.icon-arrow {
							color: var(--tuna);
						}
                    }

                    span:not(.arrow-btn) {
                        color: var(--blue);
                    }
                }

                .arrow-btn {
                    justify-content: center;
                    align-items: center;
                    background-color: var(--blue);
                    margin-right: ac(21px, 10px);

                    .icon-arrow {
                        transform: rotate(90deg);
                    }
                }

                span:not(.arrow-btn) {
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 28px;
                    transition: 0.3s ease;
                }
            }
        }
    }

	&__tag-box {
		display: flex;
		align-items: flex-start;
		max-width: 809px;

		.first__tags {
			margin-right: 20px;
			margin-top: 0px;
		}
	}

	&__keys {
		margin-top: ac(58px, 35px);
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
	}

	&__key {
		margin-right: 12px;
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;
		padding: 10px 15px;
		background-color: rgba(108, 164, 255, .5);
		border-radius: 7px;
		cursor: pointer;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: rgba(108, 164, 255, 1);
		}
	}

	&__suptitle {
		position: relative;
		color: var(--pastel);
		font-weight: 600;
		font-size: ac(18px, 16px);

		&::before {
			content: "";
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--pastel);
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
	}

	&__col {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		width: 100%;
		max-width: ac(809px, 550px, 1024, 1440);
		padding-bottom: ac(239px, 189px, 1024, 1440);
		margin-right: 15px;
	}

	&__title-box {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		padding-top: ac(60px, 30px, 1024, 1440);
		padding-right: ac(80px, 40px, 1024, 1440);
		padding-bottom: ac(74px, 30px, 1024, 1440);

		&::before {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: calc(100% + 50vw);
			height: 100%;
			border-radius: 0 7px ac(150px, 70px) 0;
			z-index: -1;
		}
	}

	&__title {
		& > span {
			position: relative;

			& > span {
				position: absolute;
				bottom: ac(-12px, -8px);
				left: 0;
				width: 100%;
				height: ac(13px, 8px);
				background-color: var(--blue);
				z-index: -1;
				border-radius: 7px;
			}
		}
	}

	&__subtitle {
		margin-top: ac(74px, 27px, 1024, 1440);
		font-size: ac(26px, 22px);
		line-height: ac(35px, 30px);
		font-weight: 600;
		max-width: ac(580px, 300px);
	}

	&__submit {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 48px;
		height: 48px;

		&.static {
			position: static;
		}

		span {
			font-size: 21px;
			line-height: 48px;
		}
	}

	&__tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: ac(13px, 20px) ac(-16px, -21px) -11px 2px;

		span {
			opacity: 0.5;
		}

		span,
		a {
			font-size: ac(15px, 14px);
			margin-right: ac(14px, 21px);
			margin-bottom: 11px;
		}

		a {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: ac(-1px, -2px);
				width: 100%;
				height: 2px;
				background-color: var(--blue);
			}
		}
	}

	&__grid-head {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--lily);
        background-color: var(--lily);
        border-radius: 30px;
        font-weight: 600;
        font-size: ac(23px, 18px);
        line-height: ac(28px, 22px);
        padding: ac(30px, 20px, 651, 1440) ac(30px, 20px, 651, 1440);
        transition: 0.3s ease !important;
        cursor: pointer;

        p {
            margin-right: 30px;
        }

        .first__btn {
            margin-right: 0;
            width: 29px;
            height: 29px;
            margin-bottom: auto;
			background-color: var(--blue);

            span {
                font-size: 15px;
                line-height: 29px;
                transform: rotate(90deg);
				color: var(--tuna);
            }
        }

        &:hover,
        &.open {
            background-color: var(--tuna) !important;
            color: var(--white) !important;
        }
    }

    &__grid-content {
        max-height: 0;
        overflow: hidden;

        transition: 0.5s ease;
        opacity: 0;

        div {
            padding: 30px;
            border: 1px solid var(--lily);
			border-radius: 30px;
			background-color: var(--lily);
        }

        &.open {
            opacity: 1;
            max-height: 2000px;
        }
    }

	&--calculator {
		height: auto !important;
		padding-top: ac(215px, 150px) !important;
		padding-bottom: ac(60px, 35px);
		border: none;

		.first {
			&__suptitle {
				display: inline-block;
				margin-bottom: 21px;
			}

			&__title {
				margin-bottom: 40px;
			}

			&__motto {
				font-size: ac(26px, 20px);
				font-weight: 600;
				line-height: 1.2;
				max-width: 809px;

			}

			&__wrapper {
				align-items: flex-start;
			}

			&__thumbnail {
				margin-top: ac(-61px, 0px);
				margin-left: ac(46px, 15px);
				flex-shrink: 0;
			}
		}
	}

	@media (min-width: 770px) {
        &__grid {
            display: grid;
            gap: 15px;
            grid-template-areas:
				"item item item"
				"desc desc desc";
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__grid-content {
            grid-area: desc;
        }
    }

	@media (min-width: 1024px) {
		&--home {
			height: calc(100vh - 300px);

			.first__col {
				min-height: 630px;
			}
		}

		&__col {
			height: 100%;
			padding-bottom: 0;
		}

		&__title-box {
			justify-content: center;
			height: 79%;
			padding-right: ac(80px, 40px, 1024, 1440);
			padding-bottom: 10px;
			padding-top: 0;
		}

		&__subtitle {
			margin-top: ac(30px, 10px, 1366, 1440);
		}

		&__form {
			margin-top: ac(26px, 10px, 1366, 1440);
		}
	}

	@media (min-width: 1920px) {
		&--member {
			.container {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
	
			.first__img {
				position: relative;
			}
		}
		
	}

	@media (max-height: 1080px) {
		&--home {
			height: 100vh;
		}
	}

	@media (max-height: 790px) {
		&--home {
			height: auto;
		}
	}

	@mixin tab {
		&--home {
			height: auto !important;
			padding-bottom: 60px;
		}

		&--contact {
			padding-top: ac(216px, 146px);
			padding-bottom: 40px !important;
			height: auto !important;
			border-bottom: none;
	
			.first__contact-info {
				margin-top: 20px;
				padding: ac(30px, 20px) ac(45px, 15px);

				a:first-child {
					margin-top: 0;
				}

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					border-radius: 30px;
					transform: rotate(0deg);
				}
			}
		}

		&--member {
			padding-bottom: ac(380px, 340px, 375, 1024);

			.first__img {
				max-width: 600px;
				width: 100%;
				bottom: 0;
			}
		}

		&--resources {
            padding-bottom: 0;

            .first__select-box {
                flex-wrap: wrap;

                .choices .choices__list--single .choices__item {
                    max-width: unset;
                }
            }

            .first__form {
                padding-right: ac(100px, 60px, 650, 1024);
            }
        }

		&--careers {
			.first__img-box {
				position: relative;
				bottom: unset;
				right: unset;
				padding-top: 410px;
				margin: 0 auto;
			}

			.first__descr, .first__col  {
				max-width: unset;
			}
		}

		&--about {
			.first__img-box {
				bottom: -400px;
				right: 50%;
				
			}

			.first__text-box {
				margin-top: 450px;
				max-width: unset;
			}
		}

		&__wrapper {
			flex-direction: column;
		}

		&__col {
			margin-right: 0;
			padding-bottom: 0;
		}

		&__title-box {
			padding-top: 21px;
			padding-right: 0px;
			padding-bottom: 53px;
		}

		&__subtitle {
			margin-top: ac(60px, 30px, 375, 1024);
		}

		&__img-box {
			bottom: 72%;
		}

		&--calculator {
			padding-top: ac(150px, 103px) !important;

			.first__wrapper {
				flex-direction: column-reverse;
			}

			.first__thumbnail {
				margin-left: auto;
				margin-right: 0;
				margin-bottom: ac(-40px, -30px, 375, 1023);
				z-index: -1;
				max-width: 68%;
			}
		}

		&__tag-box {
			flex-direction: column-reverse;

			.first__tags {
				margin-top: 20px;
				margin-right: 0;
			}
		}
	}


	@mixin tab-sm {
        &__grid-head {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        &__grid-content {
            & > div {
                margin-top: 5px;
            }
        }

        &--resources {
            .first__select-box {
                .choices {
                    margin-left: 9px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        min-width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        margin: 5px 0 0;
                        width: 100%;
                    }
                }
            }
        }
    }


	@mixin mob-lg {
		&--member {
			.first__img {
				max-width: 90%;
			}
		}

		&--careers {
			.first__award {
				bottom: 245px;
				left: -155px
			}
		}

		&__title-box {
			padding-top: 5px;
		}

		&__form {
			padding: 0 80px 0 20px;
			margin-top: 20px;
			margin-left: 0;
			flex-direction: column;

			input {
				border-right: 0;
				border-bottom: 1px solid var(--tuna);
			}

			.choices__inner,
			input {
				padding-left: 0;
			}

			.choices__item--selectable,
			input {
				line-height: 52px;
				max-width: 100%;
			}
		}

		&__form--hidden {
			padding-right: 20px;
		}

		&__submit {
			right: 10px;
			top: 28px;
		}

		&__tags {
			margin-left: 0;
		}
	}
}
	