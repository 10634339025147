.reviews {
	$gap: ac(263px, 100px);
	margin-top: $gap;
}

.reviews--prof {
	$gap: 0;
	margin-top: $gap;
	margin-bottom: -1px;
}

.reviews--org {
	$gap: ac(267px, 100px);
	margin-top: $gap;
}

.reviews {
	position: relative;
	padding: 30px 0 94px;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 85%;
		height: 100%;
		border-radius: 30px;
		background-color: var(--tuna);
		z-index: -1;
	}

	&--prof {
		padding: ac(137px, 80px) 0 ac(164px, 80px);

		.reviews__descr {
			.simplebar-content>div>* {
				font-family: var(--font-main);
			}
		}

		.reviews__text-box {
			.swiper-slide {
				padding-top: 36px;
			}
		}

		.reviews__bg {
			right: ac(-228px, -145px, 1024, 1440);
			top: calc(50% + 36px);
		}

		&::before {
			width: 100%;
			border-radius: 0;
		}
	}

	&--org {
		padding: 33px 0 87px;

		.reviews__descr {
			max-width: 520px;

			.simplebar-content>div>* {
				font-family: var(--font-main);
			}
		}

		.reviews__bg {
			right: ac(-226px, -145px, 1024, 1440);
			top: calc(50% + 20px);
		}

		.reviews__text-box {
			.swiper-slide {
				padding-top: 33px;
			}
		}
	}

	&__bg {
		height: ac(570px, 300px, 1024, 1440);
		width: ac(570px, 300px, 1024, 1440);
		position: absolute;
		right: ac(-222px, -145px, 1024, 1440);
		top: calc(50% + 20px);
		transform: translateY(-50%);
		z-index: -1;

		img {
			object-fit: contain;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&__title {
		position: relative;
		margin-right: ac(433px, 20px);
		z-index: 2;
	}

	&__rate {
		width: ac(191px, 108px);
		height: ac(29px, 16px);
		margin-top: 36px;

		img {
			object-fit: contain;
		}
	}

	&__slider {
		margin-top: 30px;
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	.reviews__thumb,
	.reviews__text {
		width: 100% !important;
		height: 100%;
	}

	&__thumb-box,
	&__text-box {
		height: 314px;
	}

	&__thumb-box {
		position: relative;
		flex: 0 0 auto;
		min-width: ac(385px, 320px, 1024, 1440);
		margin-right: ac(137px, 80px, 1024, 1440);
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: ac(0px, 5px, 1024, 1440);
			right: ac(0px, 10px, 1024, 1440);
			width: ac(580px, 500px, 1024, 1440);
			height: ac(580px, 500px, 1024, 1440);
			background-color: var(--pastel);
			transform: rotate(-15deg) translate(15%,-25%);
			border-radius: 60px;
			z-index: -1;
		}

		.swiper-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease;

			&:hover,
			&-thumb-active {
				opacity: 1;
				cursor: pointer;
			}
		}
	}

	&__text-box {
		width: 100%;
		max-width: ac(505px, 375px, 1024, 1440);

		.swiper-slide {
			display: flex;
			flex-direction: column;
			padding-top: 40px;
			
			& > span {
				&:first-child {
					flex: 0 0 auto;
					width: 40px;
					height: 20px;
					margin-left: ac(7px, 0px);

					img {
						object-fit: contain;
					}
				}
			}
		}
	}

	&__nav {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin-right: ac(56px, 20px, 1024);
		margin-top: -16px;

		.arrow-btn {
			position: relative;
			margin: 15px 0;
			background-color: transparent;
			width: 37px;
			height: 37px;
			font-size: 22px;

			span {
				line-height: 37px;
				color: var(--tuna);
				z-index: 1;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: rotate(30deg);
				background-color: var(--white);
				border-radius: 3px;
				transition: .3s ease;
			}

			&:hover {
				&::before {
					background-color: var(--blue);
				}
			}
		}
	}

	&__card {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 390px;
	}

	&__ava {
		flex: 0 0 auto;
		width: 88px;
		height: 88px;
		margin-right: 15px;
		border-radius: 20px;
		overflow: hidden;
	}

	&__name {
		font-weight: 600;
		font-size: ac(18px, 16px);
		line-height: ac(24px, 19px);
	}

	&__company {
		margin-top: 7px;
		font-size: ac(15px, 14px);
		line-height: ac(22px, 20px);
	}

	&__descr {
		margin-top: 20px;

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: ac(210px, 225px);
		}

		.simplebar-content {

			&>div {
				padding-right: 10px;

				& > * {
					font-family: var(--font-sec);
					color: var(--white);
					font-size: ac(18px, 16px);
					line-height: ac(26px, 22px);
					font-weight: 500;

					&:not(:last-child) {
						margin-bottom: ac(30px, 21px);
					}
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--pastel);
			opacity: 1;
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	@mixin tab {
		overflow: hidden;

		&::before {
			width: 100%;
		}

		&__rate {
			margin-left: auto;
		}

		&__slider {
			flex-direction: column;
		}

		&--prof {
			padding-top: 0;
		}

		&__thumb-box,
		&__text-box,
		&__nav {
			height: auto;
		}

		&__text-box {
			max-width: 100%;
		}

		&__thumb-box {
			width: 100%;
			margin-right: 0;
	
			&::before {
				top: ac(-20px, -10px);
				left: ac(-20px, -10px);
				width: calc(100% + ac(40px, 20px));
				height: calc(100% + ac(40px, 20px));
				transform: rotate(0);
				border-radius: 30px;
			}
		}

		&__nav {
			flex-direction: row;
			margin: 30px 0 -26px auto;
			z-index: 2;

			.arrow-btn {
				width: 37px;
				height: 37px;
				margin: 0 0 0 10px;

				span {
					line-height: 37px;
				}
			}
		}

		&__ava {
			width: ac(88px, 60px, 375, 1024);
			height: ac(88px, 60px, 375, 1024);
			margin-right: 20px;
		}

		&__descr {
			margin-top: 28px;
		}

		&__title {
			color: var(--white);
		}

		&__bg {
			display: none;
		}
	}

	@mixin tab-sm {
		&__wrapper {
			padding: 0;
		}

		&__bg {
			display: none;
		}
	}

	@mixin mob-sm {
		&__thumb-box {
			min-width: auto;
			width: 95%;
			margin-left: 2.5%;
		}
	}
}
