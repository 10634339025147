.intro {
	$gap: ac(150px, 80px);
    margin-top: $gap;

	&__wrap {
        display: flex;
        align-items: center;
    }

    &__inner {
        max-width: ac(603px, 470px, 900, 1440);
    }

    &__title {
        max-width: 500px;
        margin-bottom: 40px;
    }

    &__subtitle {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: ac(23px, 20px);
        line-height: 120%;
    }

    &__descr {
        font-size: 15px;
        line-height: 22px;

        p {
            margin-bottom: 8px;
        }
    }

    .btn {
        margin-top: 40px;
    }

	&__img {
		max-width: 397px;
		width: 100%;
		@mixin aspect-ratio 397,473;
        margin-right: ac(118px, 25px, 1024, 1440);

        img {
            border-radius: 30px;
        }
	}

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
        }

        &__img {
            margin-bottom: 40px;
        }

        &__inner {
            max-width: 603px;
        }
    }
}