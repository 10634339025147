.meet-team {
    $gap: ac(150px, 80px);
    margin-top: $gap;
    position: relative;

    &--careers {
        .slider-nav {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            width: 100%;
            user-select: none;
            
            .arrow-btn {
                margin: 0 5px;
                transform: rotate(15deg);
                width: ac(48px, 37px);
                height: ac(48px, 37px);
    
                span {
                    line-height: ac(48px, 37px);
                    font-size: ac(28px, 22px);
                    transform: rotate(-15deg);
                }
            }

        }

        .meet-team {
            &__heading {
                margin-bottom: ac(42px, 25px);
            }

            &__slider {
                display: flex;
                flex-wrap: wrap;
            }

            &__card {
                padding: ac(30px, 15px) ac(30px, 15px) ac(38px, 15px);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
    
            &__preview {
                &::before {
                    display: none;
                }
            }
    
            &__img {
                width: 100%;
                height: ac(335px, 258px, 320, 1440);
                border-radius: 30px;
                overflow: hidden;
            }
    
            &__pos {
                margin-bottom: ac(47px, 21px);
            }
    
            &__btn-box {
                margin-top: auto;
                position: relative;
                display: flex;
                width: 100%;
            }
    
            &__hidden {
                bottom: 45px;
                left: 0;
                right: auto;
                max-height: unset;
                visibility: hidden;

                &:last-child {
                    left: 0;
                }
            }
    
            &__btn {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
    
                .arrow-btn {
                    margin-right: 10px;
                    width: 29px;
                    height: 29px;
                    background-color: var(--blue);
    
                    span {
                        font-size: 14px;
                        line-height: 29px;
                        transform: rotate(90deg);
                        transition: .3s linear;
                        color: var(--tuna);
                    }
                }
    
                &>span {
                    &:last-child {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 22px;
                        color: var(--white);
                    }
                }
    
                &--weak {
                    margin-left: ac(65px, 15px);
                }
    
                &:hover {
                    .arrow-btn {
                        background-color: var(--pastel);
    
                        span{
                            transform: none;
                        }
                    }
                }
    
                &--weak:hover~.meet-team__hidden--weak,
                &--str:hover~.meet-team__hidden--str {
                    opacity: 1;
                    visibility: visible;
    
                }
    
                &--weak:hover~.meet-team__hidden--weak {
                    left: auto!important;
                    right: 0!important;
                }
            }
        }

        .meet-team-slider {
            width: 100% !important;
        }

        .swiper-slide {
            max-width: 397px;
        }

        
    }

    &--member {
        .meet-team__heading {
            margin-bottom: ac(40px, 25px);
        }
    }

    &__heading {
        max-width: 603px;
        margin-bottom: ac(55px, 25px);
    }

    &__slider {
        &.swiper-initialized {
            .swiper-slide {
                height: auto;
            }
        }
    }

    &__subtitle {
        margin: ac(40px, 20px) 0 ac(22px, 18px);
        font-size: ac(23px, 20px);
        line-height: 120%;
    }

    &__decor {
        position: absolute;
        right: ac(117px, 30px, 1024, 1440);
        top: -60px;
        width: ac(391px, 200px);
        height: ac(310px, 200px);

        img {
            object-fit: contain;
        }
    }

    &__descr {
        font-size: 15px;
        line-height: 22px;
    }

    &__card {
        width: 100%;
        height: 100%;
        background: var(--tuna);
        border: 1px solid var(--tuna);
        border-radius: 30px;
        padding: ac(30px, 15px);

        &:hover {
            .meet-team__excerpt {
                opacity: 1;
            }
        
            .meet-team__preview:before {
                opacity: 0.5;
            }
        }
    }

    &__img {
        @mixin aspect-ratio 337, 337;
    }

    &__preview {
        position: relative;
        border-radius: 30px;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--blue);
            z-index: 2;
            pointer-events: none;
            transition: 0.3s ease;
            opacity: 0;
        }
    }

    &__icon {
        position: absolute;
        width: 29px;
        height: 29px;
        border-radius: 7px;
        background: var(--tuna);

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 15px;

        top: 15px;
        right: 15px;

        i {
            color: var(--white);
            z-index: 20;
        }
    }

    &__name {
        margin: 16px 0 3px;
        font-size: ac(23px, 22px);
        color: var(--white);
        font-weight: 600;
    }

    &__pos {
        font-size: ac(18px, 16px);
        font-weight: 600;
        color: var(--white);
    }

    &__excerpt {
        color: var(--tuna);

        .simplebar-vertical {
            top: 15px !important;
            bottom: 15px !important;
        }
    }

    &__hidden,
    &__excerpt {
        position: absolute;
        bottom: 11px;
        left: 11px;
        right: 11px;
        font-size: 15px;
        max-height: ac(230px, 200px, 1023, 1024);
        max-width: 294px;
        background: var(--lily);
        padding: 20px;
        border: 1px solid var(--tuna);
        border-radius: 30px;
        z-index: 5;
        transition: 0.3s ease;
        opacity: 0;

        p {
            font-size: 15px;
            line-height: 22px;
        }
    }

    @media (min-width: 1024px) {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 15px;
        }
    }
    
    @mixin tab {
        &__decor {
            display: none;
        }
    }
}

