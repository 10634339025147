.offices {
    $gap: 80px;
    margin-top: $gap;
    padding: ac(150px, 80px, 1024, 1440) 0;
    background-color: var(--blue);
    border-radius: 30px 30px 0 0;

    &__title {
        margin-bottom: 37px;
        margin-left: ac(4px, 0px);
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 36px;
    
        li {
            display: flex;
            flex-direction: column;

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                h3 {
                    margin-bottom: ac(30px, 15px, 1024, 1440);
                }
            }
        }
    
        h3 {
            font-size: ac(26px, 22px);
            padding-bottom: 1px;
            border-bottom: 2px solid var(--white);
            display: inline-block;
            align-self: flex-start;
            min-width: 168px;
            margin-bottom: ac(22px, 15px, 1024, 1440);
            font-weight: 600;
        }
    
        p {
            font-size: ac(18px, 16px);
            font-weight: 500;
            line-height: 1.4;
            margin-bottom: 9px;
    
            a {
                font-size: inherit;
                margin-bottom: 0;
            }
        }
    
        a {
            font-size: 15px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 10px;

            &:hover {
                color: var(--lily);
            }
        }
    
        @media (max-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 550px) {
            grid-template-columns: 1fr;
    
            br {
                display: none;
            }
        }
    }
}

