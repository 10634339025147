.questions {
    position: relative;
    $gap: ac(150px, 80px);
    margin-top: $gap;

    &__title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__accordions {
        max-width: 810px;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
    }

    .btn {
        display: block;
        width: fit-content;
        margin: 0 auto;
    }

    &__decor {
        position: absolute;
        top: -3px;
        width: ac(482px, 240px);
        height: ac(701px, 350px);

        &:nth-child(1) {
            right: ac(-322px, -200px);
        }

        &:nth-child(2) {
            left: ac(-322px, -200px);
        }

        img {
            object-fit: contain;
        }
    }

}