.quiz-section {
    $gap: ac(154px, 50px);
    margin-top: $gap;

    .title {
        margin-bottom: 40px;
        font-size: ac(31px, 30px);
        line-height: 1.2;
    }
}

.quiz-list {
    display:grid;
    grid-template-columns: 1fr;

    .first__grid-head{
        padding-bottom: 28px;

        .first__btn{
            margin: 0;
        }
    }

    .first__grid-content {

        div{
            margin-bottom: 15px;
        }
    }

}

@media (max-width: 769px){
    .quiz-list{
        grid-template-columns: 1fr;

        .first__grid{
            margin-bottom: 15px;
            grid-gap: 15px;
        }

        .first__grid-content div{
            margin-bottom: 0;
            margin-top: 15px;
        }
    }
}
