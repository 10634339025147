html {
	font-size: ac(18px, 16px);
	line-height: ac(22px, 19px);
	font-family: var(--font-main);
	font-weight: 500;
	color: var(--tuna);
	scrollbar-width: thin;
	scrollbar-color: transparent var(--tuna);

	&::-webkit-scrollbar {
		width: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--tuna);
	}
}

body {
	min-width: 320px;
	margin: 0;
	background-color: var(--white);

	&.no-scroll {
		overflow: hidden;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.swiper {
	width: 100% !important;
}

.swiper-wrapper {
	left: 0 !important;
}

.swiper-button-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

#site {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

section {
	/* overflow-x: hidden; */
}

.main {
	flex-grow: 1;
}

.container {
	width: 100%;
	max-width: 1298px;
	margin: 0 auto;
	padding: 0 38px;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.simplebar-content {
    &.active {
        & > * {
            padding-right: 15px;
        }
    }
}

.choices {
    flex-grow: 1;
    margin-bottom: 0;

    &::after {
        display: none;
    }

    .choices__list {
        width: 100%;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &--dropdown {
            z-index: 2;

            .choices__item, .choices__input {
                line-height: 30px;
            }

            .choices__item {
                &::after {
                    display: none;
                }
            }
        }

        & > div {
            padding-right: 20px;
            width: calc(100% + 20px);
            max-height: 200px;
        }

        .simplebar-track.simplebar-vertical {
            width: 9px;
            right: 20px;
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--pastel);
            opacity: 1;
        }

    }

    .choices__inner,
    .choices__list--single {
        padding: 0;
        padding-bottom: 0 !important;
    }

    .choices__inner {
        background-color: var(--white);
        border: 0;
    }

    .choices__item--selectable {
        font-size: 15px;
        font-weight: 300;
        color: var(--tuna);
        line-height: 48px;
        opacity: 1;
    }
}

input {
    border-radius: 0;

	&,
	&::placeholder {
		font-size: 15px;
		font-weight: 300;
		color: var(--tuna);
	}
}

.arrow-btn {
	flex: 0 0 auto;
	display: inline-flex;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 7px;
	background-color: var(--tuna);
	transition: 0.3s ease;

	span {
		color: var(--white);
		line-height: 48px;
		transition: 0.3s ease;
	}

	&:hover {
		background-color: var(--blue);
	}
    &.arrow-btn-white{
        background: var(--white);
        color: var(--tuna);
        span{
            color: var(--tuna);
        }
        &:hover{
            background-color: var(--blue);
            span{
                color: var(--white);
            }
        }
    }

    &.swiper-button-lock {
        display: none!important;
    }
}
/* 
.arrow-btn {
    flex: 0 0 auto;
    display: inline-flex;
    justify-content: center;
    width: ac(48px, 37px);
    height: ac(48px, 37px);
    border-radius: 7px;
    background-color: var(--tuna);
    transition: 0.3s ease;

    .icon-arrow-left {
        margin-right: 2px;
    }
    .icon-arrow-right {
        margin-left: 2px;
    }

    span {
        color: var(--white);
        line-height: ac(48px, 37px);
        font-size: ac(28px, 22px);
        transition: 0.3s ease;
    }

    &:hover {
        background-color: var(--blue);
    }

    &.arrow-btn-white {
        background: var(--white);
        color: var(--tuna);

        span {
            color: inherit;
        }

        &:hover {
            background: var(--blue);
            color: var(--white);
        }
    }
} */

.btn {
	display: inline-flex;
	padding: 4px 5px 4px 9px;
	font-size: 15px;
	line-height: 29px;
	background: var(--white);
	border: 1px solid #2b3245;
	border-radius: 7px;
	transition: 0.3s ease;
    cursor: pointer;

	& > span {
		&:first-child {
			margin-right: 11px;
			color: var(--tuna);
		}
	}

	.arrow-btn {
		width: 29px;
		height: 29px;
		background-color: var(--blue);

		span {
			line-height: 29px;
			color: var(--tuna);
		}
	}

	&:hover {
		background-color: var(--pastel);

		.arrow-btn {
			span {
				color: var(--white);
			}
		}
	}
}

.first {
    &__form {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 62px 6px 7px;
        width: 100%;
        max-width: 705px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--blue);

        .choices__inner {
            padding-left: 30px;
        }

        input {
            width: 100%;
            max-width: ac(370px, 245px, 1024, 1440);
            padding-left: 23px;
            padding-right: 30px;
            border-right: 1px solid var(--tuna);

            &,
            &::placeholder {
                line-height: 48px;
            }

            @mixin transparent-bg-input var(--tuna);
        }

        &--hidden {
            margin-top: 0;
            margin-bottom: 10px;
            max-width: 959px;
            padding: 6px 7px;

            .choices {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: ac(32px, 5px);
                    top: ac(19px, 24px);
                    width: 8px;
                    height: 8px;
                    border-right: 2px solid var(--tuna);
                    border-bottom: 2px solid var(--tuna);
                    transform: rotate(45deg);
                    transition: .3s ease;
                }

                &:not(:first-child) {
                    .choices__inner {
                        padding-left: ac(29px, 15px);
                    }
                }

                &:nth-child(2) {
                    min-width: ac(320px, 225px, 768, 1440);
                }

                &:not(:last-child) {
                    border-right: 1px solid var(--tuna);
                }

                &__inner {
                    padding-left: ac(22px, 15px);
                }

                &.is-open {
                    &::before {
                        transform: rotate(-130deg);
                        top: ac(22px, 28px);
                    }
                }
            }

            @mixin tab-sm {
                flex-direction: column;
                padding: 0 20px;

                .choices {
                    .choices__inner {
                        padding-left: 0;
                    }

                    &:not(:first-child) {
                        .choices__inner {
                            padding-left: 0;
                        }
                    }

                    &:nth-child(2) {
                        min-width: ac(320px, 225px, 768, 1440);
                    }

                    &:not(:last-child) {
                        border-right: 0;
                        border-bottom: 1px solid var(--tuna);
                    }
                }
            }
        }
    }

    &__accardion-head {
        position: relative;
        display: inline-flex;
        padding-right: 22px;
        padding-left: 2px;
        width: 148px;
        transition: .3s ease;
        cursor: pointer;
        transform: translateY(-4px);

        span {
            &:first-child, &:nth-child(2) {
                font-weight: 700;
                font-size: 13px;
                line-height: 19px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -6px;
                    background-color: var(--tuna);
                    transition: .3s ease;
                }
            }

            &:nth-child(2) {
                position: absolute;
                opacity: 0;
                width: 164px;
            }

            &.icon-arrow {
                position: absolute;
                color: var(--pastel);
                top: 4px;
                right: 6px;
                font-size: 14px;
                transform: rotate(90deg);
                transition: .3s ease;
            }
        }

        &:hover {
            span {
                &:first-child, &:nth-child(2) {
                    color: var(--blue);

                    &::before {
                        background-color: var(--blue);
                    }
                }
            }
        }

        &.open {
            width: 210px;
            transform: translateY(0);
            margin-bottom: 5px;

            span {
                &:first-child {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                    transition: .3s ease;
                }

                &.icon-arrow {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__accardion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: 0.5s ease;

        &.open {
            opacity: 1;
            overflow: visible;
            max-height: 1000px;
            margin-bottom: 13px;
        }
    }
}

.pagination {
    margin-top: ac(60px, 35px);
    display: flex;
    justify-content: center;

    &__num {
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
        padding-top: 1px;
        font-weight: 500;
        font-size: 18px;
        line-height: 33px;
        background-color: var(--lily);
        transition: .3s ease;
        cursor: pointer;
        user-select: none;

        &:not(:last-child) {
            margin-right: 1px;
        }

        &:hover, &.active {
            color: var(--white);
            background-color: var(--tuna);
        }
    }
}

.plyr {
	&__poster {
		background-size: cover;
	}

	&__control--overlaid {
		padding: 0;
		width: ac(88px, 53px) !important;
		height: ac(54px, 32px);
		border-radius: 10px;
		background-color: var(--blue);

		&::before {
			content: "\e90c";
			font-family: "icomoon" !important;
			color: var(--white);
			font-size: ac(26px, 18px);
			line-height: ac(54px, 32px);
			margin-left: ac(8px, 6px);
		}

		&:hover {
			background-color: var(--tuna) !important;
		}

		svg {
			display: none;
		}
	}

    .plyr__video-wrapper {
        z-index: 1;
    }
}

.plyr--video.plyr--stopped .plyr__controls {
	display: none;
}

.submit-btn {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 23px;
    flex-shrink: 0;

    span {
        padding-right: 20px;
        transition: color 0.3s;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 7px;
        background: var(--tuna);
        color: var(--white);
        font-size: ac(22px, 18px);
        transition: 0.3s;
    }

    &:hover {
        color: var(--blue) !important;

        i {
            color: var(--white) !important;
            background: var(--blue) !important;
        }
    }
    &.submit-white{
        color: var(--white);
        .icon-arrow{
            background: var(--white);
            color: var(--tuna);
        }
    }
}
.not-found-link {
    color: var(--blue);
    cursor: pointer;
    transition: .3s ease;

    &:hover {
        color: var(--pastel);
    }
}

.input-wr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        padding-bottom: 12px;
        font-size: 15px;
        font-weight: 500;
        
        b{
            color: var(--red);
        }
    }

    input, textarea {
        width: 100%;
        padding: ac(18px, 15px) ac(30px, 10px);
        border: 1px solid var(--blue);
        border-radius: 7px;
        color: var(--tuna);
        font-weight: 300;
        font-size: ac(15px, 14px);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: inherit !important;
        }

        &::placeholder {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
            font-family: inherit;
        }
    }
    textarea{
        height: 124px;
    }
}
textarea{
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: rgba(83, 190, 219, 1) rgba(83, 190, 219, 0.2);
    overflow-x: hidden;
    overflow-y: auto;
    clip-path: inset(0 0 0 0 round 7px);

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(83, 190, 219, 0.2);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(83, 190, 219, 1);
        border-radius: 0;
    }
}
.single-select-wr {
    display: flex;
    flex-direction: column;

    & > span {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 12px;
        b{
            color: var(--red);
        }
    }

    .choices {
        width: 100%;


        &__inner {
            padding: 0 ac(48px, 20px) 0 ac(30px, 15px);
            border: 1px solid var(--blue);
            border-radius: 7px;
            display: flex;
            min-height: 62px;
            align-items: center;

        }

        .choices__item--selectable {
            line-height: normal;
        }

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 5px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }
    }

    .choices__list {
        & > div {
            width: 100%;
            padding-right: 0;
        }

        &--dropdown {
            .choices__item--selectable {
                line-height: 40px;
            }
        }
    }
}

.single-range {
    padding-right: 10px;
    margin-bottom: 10px;
    height: 2px;
    border-radius: 12px;
    border: none;
    box-shadow: none;
    background: var(--blue);

    &-container {
        width: 100%;
        padding-top: 11px;
    }


    .noUi-connect {
        background: var(--blue);
    }


    .noUi-handle {
        background: var(--blue);
        width: 10px!important;
        height: 10px;
        cursor: pointer;
        border-radius: 2px;
        box-shadow: none;
        top: -4px;
        right: -10px!important;
        border: none;


        &:before,
        &:after {
            content: none;
        }
    }
}

.single-range-info {
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 0;
}

.contact-form {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px 15px;
    }

    &-caption {
        display: flex;
        align-items: center;
        font-size: ac(30px, 25px);
        margin-bottom: 49px;

        h2 {
            margin-right: 12px;
            line-height: normal;
            font-size: inherit;
        }

        .choices {
            margin-top: 2px;
            flex-grow: 1;
            max-width: 303px;

            .choices__inner {
                min-height: unset;
            }

            .choices__list--dropdown {
                .choices__list {
                    &>div {
                        max-height: 220px;
                    }
                }

                .choices__item {
                    &:first-child {
                        display: none;
                    }
                }

            }
        }

        @mixin mob-lg {
            align-items: flex-start;
            flex-direction: column;

            .choices {
                max-width: 100%;
                width: 100%;
                margin: 10px 0  0!important;
            }
        }
    }

    textarea {
        height: 124px;
        resize: none;
    }

    .submit-btn {
        margin-top: 3px;
    }

    @media (max-width: 1023px) {
        .contact-form-grid {
            grid-template-columns: 1fr;
        }
    }
}

.select-wr {

    .choices {
        border-bottom: 2px solid var(--blue);

        .choices__inner {
            min-height: auto;
        }

        .choices__list > div {
            height: auto;
            line-height: normal;
        }

        .choices__item--selectable {
            font-size: ac(30px, 25px);
            font-weight: bold;
            color: var(--tuna);
            opacity: 1;
            opacity: 0.5;
        }
        .choices__list>div{
            width: 100%;
            padding-right: 0;
        }

    }

    .choices[data-type*=select-one] .choices__input {
        display: none;
    }
}

.offices-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 51px;

    li {
        display: flex;
        flex-direction: column;
    }

    h3 {
        font-size: ac(30px, 25px);
        padding-bottom: 1px;
        border-bottom: 2px solid var(--blue);
        display: inline-block;
        align-self: flex-start;
        min-width: 168px;
        margin-bottom: 24px;
        font-weight: 600;
    }

    p {
        font-size: ac(20px, 18px);
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 8px;

        a {
            font-size: inherit;
            margin-bottom: 0;
        }
    }

    a {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 5px;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
}

.terms {
    $gap: ac(186px, 130px);
    margin-top: $gap;

    &__wrapper {
        a {
            color: var(--pastel);
            word-break: break-all;
            word-wrap: break-word;
            overflow-wrap: break-word;
            max-width: 100%;

            &:hover {
                color: var(--blue);
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(21px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(39px, 20px);
            }
        }
        h1 {
            margin-bottom: ac(50px, 25px);
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4 {
            font-size: ac(30px, 26px);
            line-height: ac(40px, 36px);
        }

        h5 {
            font-size: ac(28px, 24px);
            line-height: ac(38px, 34px);
        }

        h6 {
            font-size: ac(25px, 22px);
            line-height: ac(30px, 28px);
        }

        p, li {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            padding-left: 0;
            list-style: none;

            li {
                position: relative;
                padding-left: 24px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                    font-weight: 500;
                }
            }
        }
    }
}

.page404 {
    position: relative;
    background-color: var(--pastel);
    background: linear-gradient(-45deg, var(--blue), var(--pastel), var(--alice));
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%
        }

        50% {
            background-position: 100% 50%
        }

        100% {
            background-position: 0% 50%
        }
    }

    &__wrap {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        z-index: 2;
    }

    h1 {
        margin-bottom: 0.3em;
        color: var(--white);
    }

    p {
        font-size: ac(22px, 18px);
        line-height: 22px;
        margin-bottom: 2em;
        color: var(--white);
    }
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.grecaptcha-badge {
    z-index: 1000 !important;
    bottom: 80px !important;
}


.termsfeed-com---pc-dialog {
    .cc-cp-body-tabs {
        background: var(--white) !important;
    }

    #strictly-necessary_label, .is-inactive, .cc-pc-head-close {
        color: var(--tuna) !important;
    }

    .cc-pc-head-close {
        &:hover {
            color: var(--white) !important;
        }
    }

    .cc-cp-body-tabs-item {
        background: var(--pastel) !important;

        button {
            color: var(--white) !important;
        }
    }

    .cc-cp-body-tabs-item[active=true] {
        background: var(--white) !important;

        button {
            color: var(--tuna) !important;
            box-shadow: unset !important;
        }
    }

    .cc-cp-foot, .cc-pc-head {
        background: var(--pastel) !important;
    }

    .cc-cp-body-content {
        background: var(--white) !important;
    }

    .cc-cp-body-content-entry-title, .cc-cp-body-content-entry-text, .cc-cp-foot-byline, .cc-pc-head-title-text, .cc-pc-head-title-headline {
        color: var(--tuna) !important;

        a {
            color: var(--tuna) !important;
        }
    }

    .cc-cp-foot-save {
        align-items: center;
        display: inline-flex;
        border: 1px solid var(--tuna);
        background-color: var(--white);
        border-radius: 7px;
        margin-bottom: 0 !important;
        padding: 4px 5px 4px 9px;
        transition: .3s ease;
        color: var(--tuna);
        &::after {
            content: '';
            margin-left: 10px;
            border-radius: 7px;
            width: 29px;
            height: 29px;
            transition: .3s ease;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%2353BEDB' rx='7'/%3E%3Cpath stroke='%232B3245' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--pastel);
            color: var(--tuna);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%2353BEDB' rx='7'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                transform: unset;
            }
        }
    }

    @mixin mob-lg {
        .cc-cp-foot {
            flex-direction: column;
            padding-bottom: 20px
        }
    }
}
@media only screen and (max-width: 480px) {
    .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
        justify-content: space-between !important;
    }

    .cc-nb-buttons-container button {
        width: auto !important;
    }

    .termsfeed-com---palette-dark.termsfeed-com---nb .cc-nb-main-container {
        padding: 1.5em 1rem !important;
    }
}
.cc-nb-text, .cc-nb-title, .cc-pc-head-title-text, .cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
    font-family: var(--font-main) !important;
}


.termsfeed-com---palette-dark.termsfeed-com---nb {
    background: var(--lily) !important;
    border: 1px solid var(--tuna);
    border-radius: 7px 0 0 0;

    .cc-nb-main-container {
        padding: 1.5em 2rem !important;
    }

    .cc-nb-title, .cc-nb-text {
        color: var(--tuna) !important;
    }

    .cc-nb-text {
        margin-bottom: 0;
    }

    .cc-nb-buttons-container {
        button {
            align-items: center;
            display: inline-flex;
            font-family: var(--font-main) !important;
            border: 1px solid var(--tuna);
            background-color: var(--white);
            border-radius: 7px;
            text-transform: capitalize;
            margin-bottom: 0 !important;
            margin-top: 13px !important;
            padding: 4px 5px 4px 9px;

            transition: .3s ease;
            color: var(--tuna);

            &::after {
                content: '';
                margin-left: 10px;
                border-radius: 7px;
                width: 29px;
                height: 29px;
                transition: .3s ease;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%2353BEDB' rx='7'/%3E%3Cpath stroke='%232B3245' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
            }

            &:hover {
                background-color: var(--pastel);
                color: var(--tuna);

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%2353BEDB' rx='7'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                    transform: unset;
                }
            }
        }
    }
}

@mixin mob-lg {
	.choices {
		.choices__item--selectable {
			font-size: 14px;
		}
	}

	input {
		&,
		&::placeholder {
			font-size: 14px;
		}
	}
}

@mixin mob-sm {
	.container {
		padding: 0 15px;
	}
}
