.pay-rates-section {
    $gap: ac(135px, 65px);
    margin-top: $gap;
}

.pay-rates-section {
    .container {
        position: relative;
    }

    .title {
        margin-bottom: ac(48px, 40px);
    }
}

.pr-form {
    width: 706px;
    max-width: 80%;
    position: relative;
    z-index: 2;

    &-title {
        font-size: ac(35px, 30px);
        margin-bottom: 40px;
        color: var(--blue);
        line-height: 1.2;
    }

    &-fields-wr {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 53px 6px 7px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--blue);
        margin-bottom: -30px;
    }

    .choices {
        border-left: 1px solid var(--tuna);
        width: 50%;
        position: relative;

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 5px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }

        &:first-of-type {
            border: none;
            margin-left: 0;
            max-width: 47.7%;
        }

        &:nth-of-type(2) {
            .choices__inner {
                padding-left: ac(29px, 21px, 700, 701);
            }
        }
    }

    .choices__inner {
        padding-left: 21px;
        padding-right: ac(52px, 22px);
    }
}

.pay-rates-swiper-wr {
    background: var(--tuna);
    color: var(--white);
    padding: ac(108px, 80px) 0 95px;
    position: relative;
    transform: translate(0);
    overflow: hidden;
    border-radius: 30px 30px 0 0;
    z-index: 1;

    .container {
        position: relative;
    }

    .swiper-buttons {
        position: absolute;
        right: 10.8%;
        top: -3px;
        z-index: 2;

        .arrow-btn {
            margin: 0 10px;
            transform: rotate(15deg);
            width: ac(48px, 37px);
            height: ac(48px, 37px);

            span {
                line-height: ac(48px, 37px);
                font-size: ac(28px, 22px);
                transform: rotate(-15deg);
            }

            &.swiper-button-lock {
                display: none;
            }
        }
    }
}

.pr-card {
    display: flex;

    &-text-content {
        padding-right: 31px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-title {
        border-bottom: 2px solid var(--blue);
        margin-bottom: 30px;
        font-size: ac(26px, 24px);
        display: inline-block;
        font-weight: 600;
        padding-bottom: 0;
    }

    &-fact {
        font-size: ac(15px, 14px);
        margin-bottom: 8px;
        display: inline-block;
        font-weight: bold;
    }

    h4 {
        font-size: ac(18px, 17px);
        color: var(--pastel);
        margin-bottom: 13px;
    }

    &-description {
        line-height: 1.375;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 40px;
        max-width: 603px;

        p {
            line-height: inherit;
        }
    }

    &-options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 25px;
        margin-bottom: 33px;
    }

    &-options-title {
        font-size: ac(23px, 20px);
        font-weight: bold;
        margin-bottom: 20px;
    }

    &-options-salary {
        font-size: ac(18px, 17px);
        font-weight: 500;
        color: var(--pastel);
    }

    &-summary {
        color: #F3F6FF;
        opacity: 0.5;
    }

    &-thumbnail-wr {
        width: 397px;
        max-width: 40%;
        flex-shrink: 0;
        margin-top: 53px;
        align-self: flex-start;
    }

    &-thumbnail-caption {
        margin-bottom: 26px;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        padding-left: ac(14px, 0px);
    }

    &-thumbnail {
        position: relative;
        overflow: hidden;


        &::before {
            content: '';
            display: block;
            padding-top: 71.28%;
        }

        picture {
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: calc(50% - 15px / 2);
                height: 100%;
                object-fit: cover;
                border-radius: ac(30px, 15px);

            }

            &:last-of-type {
                img {
                    left: auto;
                    right: 0;
                }
            }
        }

    }
}

.pr-shape {
    top: 0;
    position: absolute;
    right: 0;
    transform: translate(54%,-27%);
    width: 28.98%;
    z-index: -1;
    min-width: 173px;
    @media (max-width: 1400px){
        transform: translate(67%,-25%);
    }
    @media (max-width: 992px){
        transform: translate(71%,-7%);
      }
    @media (max-width: 700px){
        top: auto;
        bottom: 0;
        transform: translate(69%,-22%);
      }
}
.swiper-buttons{
    display: flex;
    justify-content: space-between;
    width: ac(116px, 96px);
    .arrow-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: ac(48px, 40px);
        height: ac(48px, 40px);
        span{
            line-height: 1!important;
        }
    }
}

@media (max-width: 700px) {
    .pr-form-fields-wr {
        flex-direction: column;
        padding-right: 80px;

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .pr-form {

        .choices {
            width: 100% !important;
            max-width: 100% !important;
            border: none;
            border-bottom: 1px solid var(--tuna) !important;

            &:last-of-type {
                border: none !important;
            }
        }
    }

    .pr-card {
        flex-direction: column;

        &-thumbnail {
            &-wr {
                max-width: 100%;
                margin: 40px auto 0;
                display: flex;
                flex-direction: column-reverse;
            }

            &-caption {
                margin-bottom: 0;
                margin-top: 25px;
            }
        }

        &-text-content {
            padding-right: 0;
        }
    }


    .pay-rates-swiper-wr {
        .container {
            padding-bottom: 80px;
        }

        .swiper-buttons {
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .pr-form {
        max-width: 100%;
    }
    .pr-card {
        &-options {
            justify-content: flex-start;
        }
    }
}