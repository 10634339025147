.commitments {
    $gap: ac(173px, 80px);
    margin-top: $gap;

    position: relative;
    background-color: var(--blue);
    border-radius: 30px 30px 0 0;
    z-index: 1;

    &__decor {
        position: absolute;
        left: ac(-302px, -200px);
        top: -190px;
        width: ac(532px, 200px);
        height: ac(782px, 400px);
        z-index: -1;
     
        img {
            object-fit: contain;
        }
    }

    &__wrapper {
        width: calc(100% + ac(20px, 0px, 1024, 1440));
        padding: 60px 0 80px ac(321px, 120px, 1024, 1440);
    }

    &__title {
        margin-bottom: ac(60px, 35px);
    }

    &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        row-gap: 44px;
        column-gap: ac(20px, 25px, 1024, 1440);
    }

    &__icon {
        width: 70px;
        height: 70px;

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__item {
        max-width: 300px;

        .icon {
            font-size: 39px;
            color: var(--white);
        }
    }

    &__name {
        margin: 17px 0 2px;
    }

    &__desc-box {
        max-height: 234px;
        padding-right: 7px;

		.simplebar-track {
			width: 3px;
			background-color: rgb(8, 2, 35, .2);
			border-radius: 0;
		}

		.simplebar-scrollbar::before {
			background-color: var(--tuna);
			border-radius: 0;
			opacity: 1;
			top: 0 !important;
			left: 0;
			bottom: 0 !important;
			right: 0;
		}
    }

    &__desc {
        &, &>* {
            font-size: 18px;
            line-height: 26px;
        }
    }

    @mixin tab {
        &__decor {
            display: none;
        }
    
        &__wrapper {
            padding-left: 0;
        }
    
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }

        &__item {
            max-width: 100%;
        }
    }
    @mixin tab-sm {
        &__list {
            grid-template-columns: 1fr;
        }
    }
    
    @mixin mob-lg {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}


