.positions.positions--careers {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.positions.positions--prof {
    $gap: ac(150px, 80px);
    margin-top: $gap;
    margin-bottom: -1px;
}

.positions {
    $gap: 15px;
    margin-top: $gap;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        padding: ac(17px, 10px) ac(30px, 15px) ac(39px, 15px);
        width: calc(100% / 3 - 15px);
        border-radius: 30px;
        border: 1px solid var(--blue);
        min-height: ac(336px, 230px);
        transition: .3s ease !important;
        background: var(--blue);

        &--alt {
            .not-found-link {
                color: var(--white);

                &:hover {
                    color: var(--pastel);
                }
            }
        }

        &:not(.positions__card--alt):hover {
            background-color: var(--tuna);
            color: var(--white);

            .positions__btn {
                background-color: var(--white);

                .icon-arrow {
                    color: var(--tuna)
                }
            }
        }
    }

    &__btn {
        margin-left: auto;
        margin-right: ac(-16px, -6px);
        width: 29px;
        height: 29px;

        .icon-arrow {
            line-height: 29px;
            font-size: 13px;
        }
    }

    &__title {
        margin-bottom: auto;
        font-weight: 700;
        font-size: ac(31px, 24px);
        line-height: ac(40px, 32px);
    }

    &__text {
        margin-top: 10px;
        font-weight: 600;
        font-size: ac(23px, 18px);
        line-height: ac(28px, 24px);
    }

    &--careers {
        .choices {
            flex-basis: 100%;
        }
    }

    &--prof,
    &--careers {
        padding-bottom: 150px;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: ac(400px, 300px);
            background-color: var(--tuna);
            z-index: -1;
            border-radius: 30px;
        }

        .first__form {
            margin-bottom: 14px;

            &--hidden {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        .first__tag-box {
            align-items: flex-end;
        }

        .positions {
            &__form {
                position: relative;
                margin-top: ac(40px, 20px);
                z-index: 20;
                max-width: ac(960px, 800px, 1024, 1440);
            }

            &__subtitle {
                margin-top: ac(40px, 20px);
                font-weight: 600;
                font-size: ac(23px, 18px);
                line-height: ac(28px, 22px);
            }
            
            &__slider-box {
                position: relative;
                display: flex;
                margin-top: ac(56px, 30px);
                z-index: 10;
            }

            &__slider {
                width: 100% !important;
                overflow: visible !important;
            }

            &__card {
                width: 100%;
                height: 100%;

                &:hover {
                    border-color: var(--white);
                }
            }

            &__nav {
                position: absolute;
                right: 0;
                top: ac(-94px, -65px, 1024, 1440);
                user-select: none;

                .positions__prev {
                    margin-right: 15px;
                }

                .arrow-btn {
                    transform: rotate(15deg);
                    width: ac(48px, 37px);
                    height: ac(48px, 37px);
        
                    span {
                        line-height: ac(48px, 37px);
                        font-size: ac(28px, 22px);
                        transform: rotate(-15deg);
                    }
                }
            }
        }
    }

    &--prof {
        border-radius: 30px 30px 0 0;
        padding-top: ac(80px, 40px);
        background-color: var(--lily);

        &::before {
            border-radius: 30px 30px 0 0;
        }
        
        .first__form {
            max-width: 809px;
        }

        .positions__form {
            max-width: 811px;
        }

        .first__accardion-head {
            flex: 0 0 auto;
            margin-left: auto;
            width: 150px;
            padding-right: 12px;

            span {
                &:nth-child(2) {
                    right: 30px;
                }
            }
        }

        .first__tags {
            margin-left: 0;

            a {
                margin-right: 14px;
            }
        }

        .positions {
            &__form {
                margin-top: ac(49px, 25px);
                margin-left: ac(211px, 0px, 1024, 1440);

                input {
                    max-width: 405px;
                }
            }

            &__slider-box {
                margin-top: ac(50px, 30px);
            }

            &__title-box {
                display: flex;
                margin-left: ac(211px, 0px, 1024, 1440);

                h3 {
                    flex: 0 0 auto;
                    width: 100%;
                    max-width: 243px;
                    margin-right: ac(66px, 20px, 1024, 1440);
                }
            }

            &__subtitle {
                margin-top: 0;
                font-weight: 500;
                font-size: ac(18px, 16px);
                line-height: ac(26px, 22px);
            }
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }

        &--prof,
        &--careers {
            .positions__nav {
                top: unset;
                right: unset;
                left: 50%;
                transform: translateX(-50%);
                bottom: -75px;

                .positions__prev {
                    margin-right: 10px;
                }

                .arrow-btn {
                    background-color: var(--white);
                    border: 1px solid var(--tuna);
                    

                    span {
                        color: var(--tuna);
                    }

                    &:hover {
                        background-color: var(--tuna);
                        border: 1px solid var(--white);

                        span {
                            color: var(--white);
                        }
                    }
                }
            }
        }

        &--prof {
            .positions__title-box {
                flex-direction: column;

                h3 {
                    max-width: unset;
                    margin-right: 0;
                }
            }

            .positions__subtitle {
                margin-top: 20px;
                max-width: unset;
            }
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}