.ic-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: flex;

    &-registration {
        .ic-popup-container {
            .simplebar-track {

                &.simplebar-vertical {
                    width: 6px;
                    right: 0;
                }
            }
            
            .simplebar-scrollbar::before {
                background-color: var(--pastel);
            }
        }
    }

    &-bg {
        position: absolute;
        inset: 0;
        background: var(--tuna);
        opacity: 0.5;
        z-index: 0;
    }

    &-close-btn {
        background: var(--tuna);
        color: var(--white);
        width: 29px;
        height: 29px;
        display: flex;
        line-height: normal !important;
        justify-content: center;
        align-items: center;
        font-size: 12.37px;
        border-radius: 7px;
        position: absolute;
        right: ac(30px, 10px);
        top: ac(30px, 10px);
        transition: 0.3s;
        cursor: pointer;

        i {
            line-height: normal;
        }

        &:hover {
            background: var(--blue) !important;
        }
    }

    &-container {
        width: 1016px;
        max-width: 100%;
        background: var(--white);
        position: relative;
        z-index: 1;
        margin: 0 auto;
        border: 1px solid var(--tuna);
        border-radius: 7px;
        padding: ac(80px, 55px) ac(103px, 15px) ac(72px, 30px) ac(103px, 15px);
        align-self: center;
        max-height: 94vh;
        display: flex;

        .simplebar-track {
			width: 6px;
			background-color: rgba(255, 255, 255, .2);
			border-radius: 7px;
		}

		.simplebar-scrollbar::before {
			background-color: var(--white);
			opacity: 1;
			top: 0 !important;
			left: 0;
			bottom: 0 !important;
			right: 0;
            border-radius: 7px;
		}
    }

    &-container-inner {
        max-height: 100%;

        .submit-btn{
            transform: translateY(ac(-7px, 0px));
        }
    }

    &-form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px 15px;
        margin-bottom: ac(33px, 25px);
    }

    a {
        color: var(--pastel);

        &:hover {
            text-decoration: underline;
        }

    }

    h2 {
        margin-bottom: ac(43px, 25px);
    }

    h3 {
        font-size: ac(23px, 20px);
        margin-bottom: ac(22px, 15px);
        font-weight: 600;
    }
    &-offices-title{
        margin-top: 48px;
        margin-bottom: 27px!important;
    }
    .ic-popup-mail-link{
        color: var(--white);
        text-decoration: none;
        font-size: ac(23px, 18px);
        margin-bottom: 12px;
        font-weight: bold;
        &:hover{
            color: var(--pastel);
            text-decoration: none;
        }
    }
    .offices-list{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin-bottom: 26px;
        grid-gap: 25px;
        padding-right: ac(8px, 0px);
        a{
            color: var(--white);
            text-decoration: none;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.66;
            &:hover{
                color: var(--pastel);
            }
        }
        li{
            h3{
                width: fit-content;
                min-width: 0;
                margin-bottom: 9px;
                padding-bottom: 0;
                line-height: 1.66;
            }
            &>a{
                margin-top: 5px;
            }
        }
        @media (max-width: 768px){
            grid-template-columns: 1fr 1fr;
          }
        @media (max-width: 385px){
            grid-template-columns: 1fr;
          }
    }
}

.ic-popup.success {
    display: flex;
    align-items: center;
    padding: 100px 10px;

    .ic-popup {
        &-container {
            background: var(--tuna);
            max-height: 100%;
            height: auto;
        }

    }

    h2 {
        color: var(--blue);
        margin-bottom: ac(40px, 25px);
    }

    h3 {
        color: var(--white);
        font-size: ac(31px, 20px);
        margin-bottom: 0;
        font-weight: 700;
    }

    .ic-popup-close-btn {
        background: var(--white);
        color: var(--tuna);
    }
}

.ic-popup.ic-popup-refer {
    .ic-popup {
        &-container {
            background: var(--tuna);
            color: var(--white);
        }

        &-close-btn {
            background: var(--white);
            color: var(--tuna);
        }
    }
}

.upload-btn {
    padding: 10px 0 34px;
    display: flex;

    label {
        cursor: pointer;
        color: inherit;
        display: flex;
        align-items: center;
        gap: 20px;

        &:hover {
            text-decoration: underline;
        }

        input {
            display: none;
        }
    }

    div {
        min-height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--blue);
        padding: 5px 10px;
        border-radius: 7px;
        min-width: 141px;
        font-size: 15px;
        font-weight: 500;
    }
}

.checkbox-wr {
    cursor: pointer;
    font-size: 12px;
    display: block;
    margin-bottom: 3px;
    width: fit-content;
    transition: opacity 0.3s;
    font-weight: 400;

    &:hover{
        opacity: 0.8;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    & > span {
         display: block;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid var(--tuna);
            border-radius: 2px;
            margin-bottom: -2px;
        }
    }

    input:checked + span {
        &::before {
            background: var(--blue);
        }
    }

    &.checkbox-wr-white {
        & > span {
            position: relative;

            &::before {
                border: 1px solid var(--white);

            }
        }
    }
}

.upload-status {
    font-size: 15px;
    font-weight: 300;
}

@media (max-width: 1024px) {
    .ic-popup-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ic-popup-form-grid {
        grid-template-columns: 1fr;
    }

    .ic-popup-container {
        max-height: 84vh;
    }
}